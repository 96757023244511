<template>
  <a tabindex="0" @click="openComments">
    {{ label }}
    <portal :to="name">
      <modal
        ref="modal"
        v-model="modalIsOpen"
        :header="false"
        :footer="false"
        :close-with-escape="false"
        :confirm-on-enter="false"
        :close-on-confirm="false"
        :close-on-backdrop-clicks="false"
        :modal-dialog-classes="['modal-lg']"
        @backdrop-clicked="close"
      >
        <close-icon slot="before-content" @click.prevent.stop="close"></close-icon>
        <comments
          :style="commentsStyle"
          :read-only="false"
          :uploads="false"
          :comments="commentsConfiguration.comments"
          :create-url="configuration.routes.commentCreateUrl"
          :delete-url="configuration.routes.commentUpdateUrl"
          :update-url="configuration.routes.commentDeleteUrl"
          :token="commentsConfiguration.token"
          :labels="configuration.labels"
          :user-id="configuration.userId"
          :default-language="configuration.defaultLanguage"
          :language="configuration.language"
          :translations="translations"
          :initial-loading="true"
          @added="(comment, allComments) => commentAdded(allComments)"
          @deleted="(comment, allComments) => commentDeleted(allComments)"
        ></comments>
      </modal>
    </portal>
  </a>
</template>

<script>
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';
import Comments from '@/lib/components/Comments/Comments.vue';
import {Modal} from 'vue-bootstrap';
import modalMixin from '@/lib/components/ListActions/mixins/modal-mixin';
import CloseIcon from '@/lib/components/ListActions/Partials/CloseIcon.vue';
import pjax from '@/vendor/pjax/pjax';

export default {
  name: 'CommentAction',
  components: {
    Comments,
    Modal,
    CloseIcon
  },
  mixins: [langMixin, modalMixin],
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    configuration: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      modalIsOpen: false,
      commentsConfiguration: {
        comments: [],
        tags: [],
        token: ''
      },
      initialCommentsCount: 0
    };
  },
  computed: {
    commentsStyle() {
      return {
        maxHeight: '330px',
        overflowY: 'auto',
        position: 'relative',
        paddingRight: '15px'
      };
    }
  },
  methods: {
    async openComments() {
      this.modalIsOpen = true;
      const commentsData = await this.fetchComments();
      if (commentsData !== null) {
        this.commentsConfiguration = {...commentsData};
        this.initialCommentsCount = commentsData.comments.length;
      }
    },
    commentAdded(allComments) {
      this.commentsConfiguration.comments = allComments;
    },
    commentDeleted(allComments) {
      this.commentsConfiguration.comments = allComments;
    },
    async fetchComments() {
      const {data, status} = await this.$http.get(this.configuration.routes.commentListUrl);
      return status === 200
        ? {
          comments: data.comments,
          tags: data.tags,
          token: data.token
        }
        : null;
    },
    close() {
      this.modalIsOpen = false;
      if (this.initialCommentsCount !== this.commentsConfiguration.comments.length) {
        pjax.reload('#pjaxContainer');
      }
    }
  }
};
</script>

<style scoped></style>
