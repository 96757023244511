import { ModalEmitters, ModalEvents } from '@/lib/components/ListActions/composables/useModal.events';
import { nextTick, ref } from 'vue';

export function useModal(name: string, emit: ModalEmitters) {
	const showModal = ref<boolean>(false);

	const toggle = () => {
		showModal.value = !showModal.value;
		if (showModal.value) {
			nextTick(function () {
				emit(ModalEvents.Reveal, name);
			});
		}
	};

	const modalClosed = () => {
		showModal.value = false;
		nextTick(function () {
			emit(ModalEvents.Reveal, null);
		});
	};

	return { showModal, toggle, modalClosed };
}
