import FileUploadFieldWrapper from '@/modules/fields/components/FileUploadWrapper';
import { registerAutosaveCommentListeners } from '@/lib/autosaver-comment';
import { mountVueComponent, mountVueWithComponents } from '@/lib/mount-vue-component';

export default function () {
	var darkmode = require('../../common/darkmode.js');
	darkmode.mount();

	import('@/modules/users/components/UserFields').then((userFields) => {
		mountVueComponent('user-fields', userFields);
	});

	import('@/modules/documents/components/DocumentsList').then((documentsList) => {
		mountVueComponent('documents-list', documentsList);
	});

	// helpIcon
	import('@/lib/components/Shared/HelpIcon.vue').then((helpIcon) => {
		mountVueComponent('help-icon', helpIcon);
	});

	import('@/lib/components/Fields/validator/Validator').then((validator) => {
		mountVueWithComponents('validator', validator, { FileUploadFieldWrapper });
	});

	import('@/lib/components/Comments/Comments.vue').then((comments) => {
		mountVueComponent('comments', comments);
	});

  import('@/modules/grants/components/GrantList').then((grantList) => {
    mountVueComponent('grants-list', grantList);
  });
  
	registerAutosaveCommentListeners();
}
