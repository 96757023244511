var $ = require('jquery');
// eslint-disable-next-line @typescript-eslint/naming-convention
var _ = require('underscore');

/**
 * Video thumbnails - get video thumbnails from YouTube/Vimeo.
 *
 * Usage:
 *   var videoThumbnails = new VideoThumbnails;
 *   videoThumbnails.setup(elements);
 *
 */
module.exports = function () {
	// Vimeo API url
	var vimeoAPIUrl = _.template('https://vimeo.com/api/v2/video/<%= id %>.json');

	// YouTube image tag
	var youtubeImageTag = _.template('<img src="https://img.youtube.com/vi/<%= id %>/0.jpg" alt="<%= alt %>">');

	// Img tag
	var imageTag = _.template('<img src="<%= thumbnail %>" alt="<%= alt %>">');

	/**
	 * Get video thumbnail from Vimeo
	 *
	 * container -> container element that will hold the thumbnail
	 * videoId -> video id
	 */
	var getVimeoThumbnail = function (container, videoId) {
		$.ajax({
			type: 'GET',
			url: vimeoAPIUrl({ id: videoId }),
			jsonp: 'callback',
			dataType: 'jsonp',
			success: function (data) {
				const alt = $(container).find('.preview').data('alt');
				var thumbnail = data[0].thumbnail_large;
				$(container).removeClass('no-preview');
				$(container)
					.find('.preview')
					.prepend(imageTag({ thumbnail: thumbnail, alt }));
			},
		});
	};

	/**
	 * Get video thumbnail from YouTube
	 *
	 * container -> container element that will hold the thumbnail
	 * videoId -> video id
	 */
	var getYoutubeThumbnail = function (container, videoId) {
		const alt = $(container).find('.preview').data('alt');
		$(container).removeClass('no-preview');
		$(container)
			.find('.preview')
			.prepend(youtubeImageTag({ id: videoId, alt }));
	};

	/**
	 * Setup video thumbnails
	 *
	 * elements -> elements that will hold the thumbnails
	 */
	this.setup = function (elements) {
		elements = $('.' + elements);

		elements.each(function () {
			var vimeoId = $(this).data('vimeo-id');
			var youtubeId = $(this).data('youtube-id');

			if (vimeoId) {
				getVimeoThumbnail(this, vimeoId);
			}

			if (youtubeId) {
				getYoutubeThumbnail(this, youtubeId);
			}
		});
	};
};
