<template>
  <popover
    ref="popover"
    placement="right"
    origin="top"
    popover-class="columnator"
  >
    <a href="javascript:;" @click.prevent="openColumnator">
      <label class="sr-only">
        {{ labels.cog }}
      </label>
      <span class="af-icons af-icons-cog"></span>
    </a>
    <div slot="content">
      <span v-if="!loaded">{{ labels.loading }}</span>
      <div v-else>
        <div class="columnator-container">
          <h4>{{ labels.title }}</h4>
          <multiselect
            :counter="false"
            :select-all="false"
            name="columns[]"
            :options="availableColumns"
            :selected-options="columns"
            @selected="selectColumns"
          />
          <div class="actions">
            <div class="submit-action">
              <button
                type="submit"
                class="btn btn-primary"
                @click.prevent="submit"
              >
                {{ labels.save }}
              </button>
            </div>
            <a class="action-button" @click.prevent="cancel">
              {{ labels.cancel }}
            </a>
            <a class="action-button" @click.prevent="reset">
              {{ labels.reset }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </popover>
</template>

<script>
import toastr from 'toastr';
import { Popover, Multiselect } from 'vue-bootstrap';
import pjax from '@/vendor/pjax/pjax';

export default {
  components: {
    Popover,
    Multiselect
  },
  props: {
    area: {
      type: String,
      required: true
    },
    getColumnsUrl: {
      type: String,
      required: true
    },
    saveColumnsUrl: {
      type: String,
      required: true
    },
    resetColumnsUrl: {
      type: String,
      required: true
    },
    labels: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      columns: [],
      availableColumns: [],
      fixedColumns: [],
      selectedColumns: [],
      loaded: false
    };
  },
  methods: {
    openColumnator() {
      if (!this.loaded) {
        this.$http.get(this.getColumnsUrl).then(
          response => {
            this.columns = response.data.columns;
            this.availableColumns = response.data.availableColumns;
            this.fixedColumns = response.data.fixedColumns;

            this.loaded = true;
          },
          response => {
            toastr.error(this.labels.loadAlert);
          }
        );
      }
    },
    selectColumns(columns) {
      this.selectedColumns = columns.slice();
    },
    submit() {
      if (!this.selectedColumns.length) {
        return;
      }

      this.$refs.popover.close();

      this.$http
        .post(this.saveColumnsUrl, {
          area: this.area,
          setting: 'columns',
          fixedColumns: this.fixedColumns,
          columns: this.selectedColumns
        })
        .then(
          response => {
            pjax.navigate({ url: window.location.href, type: 'GET' });
          },
          response => {
            toastr.error(this.labels.saveAlert);
          }
        );
    },
    cancel() {
      this.$refs.popover.close();
    },
    reset() {
      this.$refs.popover.close();

      this.$http.post(this.resetColumnsUrl).then(
        response => {
          pjax.navigate({ url: window.location.href, type: 'GET' });
        },
        response => {
          toastr.error(this.labels.resetAlert);
        }
      );
    }
  }
};
</script>
