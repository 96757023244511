import '@/vendor/pjax/jquery.pjax.js';
import $ from 'jquery';
import { Config as ConfigPjaxRouter, Init as PjaxRouterInit} from '@/vendor/pjax/pjax-router';
import registerPjaxEventListeners from '@/vendor/pjax/pjax-listener';

const pjax = {
	options: () => $.pjax.options,
	state: () => ({...$.pjax.state}),
	defaults: () => ({...$.pjax.defaults}),
	init() {
		ConfigPjaxRouter.set('matchBehaviour', 'all');

		// The long timeout is to ensure that we don't get weird cancellation effects for
		// semi-long requests (a few hundred ms). Also helps with slow connections.
		// jquery-pjax responses will come up "cancelled" if they time out.
		let timeout = 40000;
		let pjaxContainer = '#pjaxContainer';
		let originalDefaults = $.pjax.defaults;

		$.pjax.defaults = {};
		$.pjax.defaults.timeout = timeout;
		$.pjax.defaults.container = pjaxContainer;
		$.pjax.defaults.push = true;
		$.pjax.defaults.maxCacheLength = 0;
		$.pjax.defaults.version = originalDefaults.version;

		registerPjaxEventListeners();
		PjaxRouterInit();
	},
	attachLinkHandler: (selector: string, container: string = '#pjaxContainer') => {
		$('body').pjax(selector, container);
	},
	navigate: (params:{})=> new Promise(resolve => resolve($.pjax(params))),
	submit: (event: Event, container = '#pjaxContainer') => new Promise(resolve => resolve($.pjax.submit(event, container))),
	reload: (container:string = '#pjaxContainer') => new Promise(resolve => resolve($.pjax.reload(container))),
	setVersion: (version: string) => $.pjax.defaults.version = version,
};

export default pjax;
