<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="pull-left">
		<button class="btn btn-tertiary" @click="toggle" @mouseover="loadContent">
			<icon name="matrix" />&nbsp;
			{{ lang.get('judging.score_matrix.button') }}
		</button>
		<portal :to="name">
			<modal
				v-model="showModal"
				:header="false"
				:confirm-button="false"
				:modal-dialog-classes="['model-max-width']"
				@closed="modalClosed"
			>
				<close-icon slot="before-content" @click.prevent="toggle"></close-icon>
				<h4 class="modal-title">{{ lang.get('judging.score_matrix.button') }}</h4>
				<button v-if="content" class="action-button" @click="printContent">
					{{ lang.get('judging.score_matrix.print') }}
				</button>
				<loader v-if="!content || loading" id="scoreMatrixTable" />
				<div
					v-else
					class="score-matrix-table scroll-horizontally shadows print-content"
					@click="handleLinkClick"
					v-html="content"
				></div>
				<div v-if="message" style="margin-top: 15px">{{ message }}</div>
			</modal>
		</portal>
	</div>
</template>

<script>
import { Modal } from 'vue-bootstrap';
import { defineComponent } from 'vue';
import { scoreMatrixController } from '@/modules/judging/components/ScoreMatrix.controller';
import CloseIcon from '@/lib/components/ListActions/Partials/CloseIcon.vue';
import Loader from '@/lib/components/Loader.vue';
import Icon from '@/lib/components/Shared/Icon.vue';

export default defineComponent({
	components: { Icon, Loader, Modal, CloseIcon },
	props: {
		scoreDataUrl: {
			type: String,
			required: true,
		},
	},
	setup: scoreMatrixController,
});
</script>

<style scoped>
.score-matrix-table {
	overflow: auto;
}
</style>
