import { collaboratorAccess } from '@/domain/models/Collaborator';
import { EntryFormStore } from '@/lib/store/modules/entry-form';
import { featureEnabled } from '@/domain/dao/Features';
import { useEntryFormContainer } from '@/modules/entry-form/EntryFormProvider';
import { collaborationUIBus, CollaborationUISignals } from '@/domain/signals/Collaboration';
import { Parameters, Referee } from '@/modules/entry-form/RefereeTypes';
import { submittableFormBus, SubmittableFormSignal } from '@/modules/entry-form/Signals';

const getType = (store: EntryFormStore) => (store.state.entryForm.isManager ? 'manager' : 'entrant');

const getSubmittable = (store: EntryFormStore) => store.state.entryForm.entry;

const getForm = (store: EntryFormStore) => store.state.entryForm.form;

const getFormType = (store: EntryFormStore) => store.getters['entryForm/formType'];

const entryIsSaved = (store: EntryFormStore) => getSubmittable(store) !== null;

const ajaxInProgress = (store: EntryFormStore) => store.state.entryFormApi.ajaxInProgress;

let blockCollaboration = false;

const isCollaborative = (store: EntryFormStore) => {
	if (getSubmittable(store) === null || blockCollaboration) {
		return false;
	}

	return getForm(store).collaborative && featureEnabled('collaboration');
};

const supportsRealTimeUpdates = (store: EntryFormStore) => isCollaborative(store) || isUpdatable(store);

const isUpdatable = (store: EntryFormStore) => {
	if (getSubmittable(store) === null || blockCollaboration) {
		return false;
	}

	return getForm(store).allowApiUpdates && featureEnabled('api_submission_updates');
};

const entryFormService = (store: EntryFormStore) => {
	collaborationUIBus.on(CollaborationUISignals.UPDATED_COLLABORATORS, (payload) => {
		store.commit('entryForm/setCollaboratorAccess', collaboratorAccess(payload.myself));
	});

	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { Consumer } = useEntryFormContainer();
	const consumerSlug = Consumer.consumer?.slug;

	submittableFormBus.on(SubmittableFormSignal.SUBMITTED, (payload) => {
		store.commit('entryForm/setReadOnly', payload.submittedUserSlug !== consumerSlug);
	});

	const fieldHasError = (field: string, resourceId: string | number): boolean =>
		store.getters['entryFormApi/fieldErrors'](field, resourceId).length > 0;

	return {
		getSubmittable: () => getSubmittable(store),
		getForm: () => getForm(store),
		getFormType: () => getFormType(store),
		isSaved: () => entryIsSaved(store),
		isCollaborative: () => isCollaborative(store),
		supportsRealTimeUpdates: () => supportsRealTimeUpdates(store),
		isUpdatable: () => isUpdatable(store),
		toggleCollaborative: (state: boolean) => (blockCollaboration = state === false),
		getType: () => getType(store),
		isGrantReport: () => window.location.href.includes('grant-report'),
		removeFromErrorBag: (error: any) => store.commit('entryFormApi/removeFromErrorBag', error),
		addToErrorBag: (error: any) => store.commit('entryFormApi/addToErrorBag', error),
		updateRefereeField: (parameters: Parameters['updateReferee']) =>
			store.commit('entryForm/updateRefereeField', parameters),
		updateReferee: (referee: Referee) => store.commit('entryForm/updateReferee', referee),
		autoSave: () => store.dispatch('entryFormApi/autosavePromise'),
		fieldHasError,
		ajaxInProgress: () => ajaxInProgress(store),
	};
};

export { entryFormService, getSubmittable, getForm };
