import pjax from '@/vendor/pjax/pjax';
import { computed, ComputedRef, Ref, ref, SetupFunction, watch } from 'vue';

type Column = {
	name: string;
	title: string;
};

type Props = {
	area: string;
	columns: {
		allColumns: Column[];
		area: string;
		defaultColumns: string[];
	}[];
};

type View = {
	exportName: Ref<string>;
	selectedArea: Ref<string>;
	availableColumns: ComputedRef<Column[]>;
	selectedColumns: ComputedRef<string[]>;
	selectArea: () => void;
	shared: Ref<string>;
};

const newCustomExportLayoutController: SetupFunction<Props, View> = (props: Props): View => {
	const params = new URLSearchParams(window.location.search);

	const selectedArea = ref(params.get('area') || props.area);
	const exportName = ref(params.get('exportName') || '');
	const shared = ref(params.get('shared') || '0');

	watch(exportName, (newValue, oldValue) => {
		if (newValue !== oldValue) updateUrl();
	});

	watch(shared, (newValue, oldValue) => {
		if (newValue !== oldValue) updateUrl();
	});

	const availableColumns = computed(() => {
		const columns = findColumn(selectedArea.value);
		return columns ? columns.allColumns : [];
	});

	const selectedColumns: ComputedRef<string[]> = computed(() => {
		const columns = findColumn(selectedArea.value);

		return (columns ? columns.defaultColumns : [])
			.map((name) => availableColumns.value.find((column) => column.name === name))
			.filter((column): column is { name: string; title: string } => !!column)
			.sort((a, b) => a.title.localeCompare(b.title))
			.map((column) => column.name);
	});

	const findColumn = (area: string) => props.columns.find((column) => column.area === area);

	const buildQueryParams = () => {
		const queryParams = new URLSearchParams();
		if (selectedArea.value) queryParams.set('area', selectedArea.value);
		if (exportName.value) queryParams.set('exportName', exportName.value);
		if (shared.value) queryParams.set('shared', shared.value);

		return queryParams.toString();
	};

	const updateUrl = () => {
		const newUrl = `${window.location.pathname}?${buildQueryParams()}`;
		history.replaceState(null, '', newUrl);
	};

	const selectArea = () => {
		updateUrl();
		pjax.navigate({
			url: `${window.location.pathname}?${buildQueryParams()}`,
			container: '#pjaxContainer',
			method: 'GET',
		});
	};

	return {
		exportName,
		selectedArea,
		availableColumns,
		selectArea,
		selectedColumns,
		shared,
	};
};

export { Props, View, newCustomExportLayoutController };
