import buttons from './buttons.js';
import index from './index.js';
import { Router } from '@/legacy/Router';
import slideshow from './slideshow.js';
import vote from './vote.js';

Router.get('vote/:any', buttons);
Router.get('vote/([a-zA-Z]+)', index);
Router.get('vote/([a-zA-Z]+)/([a-zA-Z]+)', vote);
Router.get('vote/([a-zA-Z]+)/([a-zA-Z]+)/slideshow', slideshow);
