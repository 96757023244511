<script>
export default {
	props: {
		score: {
			type: Number,
			default: null,
		},
		lockScores: {
			type: Boolean,
			default: false,
		},
		maxScore: {
			type: [Number, String],
			default: 10,
		},
		minScore: {
			type: [Number, String],
			default: 0,
		},
	},
};
</script>
