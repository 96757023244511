import form from './form.js';
import { Router } from '@/legacy/Router';

Router.get('entry-form/entrant/start', form);
Router.get('entry-form/entrant/([a-zA-Z]+)/edit', form);
Router.get('entry-form/manager/start', form);
Router.get('entry-form/manager/([a-zA-Z]+)/edit', form);

Router.get('grant-report/entrant/start', form);
Router.get('grant-report/entrant/([a-zA-Z]+)/edit', form);
Router.get('grant-report/manager/start', form);
Router.get('grant-report/manager/([a-zA-Z]+)/edit', form);
