import $ from 'jquery';
import { mountVueComponent } from '@/lib/mount-vue-component';
import pjax from '@/vendor/pjax/pjax';
export default function () {
	import('./components/BroadcastForm').then((broadcastForm) => {
		mountVueComponent('broadcast-form', broadcastForm);

		const toggleScheduleOption = function (show) {
			if (show) {
				$('#datetimeSelector').removeClass('hidden');
				$('#review-send-text').addClass('hidden');
				$('#review-schedule-text').removeClass('hidden');
			} else {
				$('#datetimeSelector').addClass('hidden');
				$('#review-send-text').removeClass('hidden');
				$('#review-schedule-text').addClass('hidden');
				$('#dueDate').val('');
				$('input[name="dueDate"]').first().val('');
			}
		};

		$(document).ready(function () {
			if ($('#schedule-option-schedule').is(':checked')) {
				toggleScheduleOption(true);
			}
		});

		$('#schedule-option-immediately').click(function () {
			toggleScheduleOption(false);
		});

		$('#schedule-option-schedule').click(function () {
			toggleScheduleOption(true);
		});

		$('#review-send').click(function (e) {
			e.preventDefault();

			var form = $(this).parents('form');
			var action = form.attr('action');

			form.attr('action', action + '/review');
			form.submit();
		});

		$('#discard').click(function (e) {
			e.preventDefault();

			var form = $(this).parents('form');
			var method = form.find('[name="_method"]');

			$(method).val('DELETE');

			form.submit();
		});

		$('.button-pill-close.submit').click(function () {
			var form = $(this).parents('form');
			form.submit();
		});

		$('#legalBasis').change(function () {
			var url = new URL(window.location.href);
			url.searchParams.set('legalBasis', $(this).val());
			var form = $(this).parents('form');
			pjax.navigate({ url: url.href, data: form.serialize(), type: 'POST' });
		});

		$("[name='consolidateRecipients']").change(function () {
			const url = new URL(window.location.href);
			url.searchParams.append('legalBasis', $('#legalBasis').val());
			const form = $(this).parents('form');
			pjax.navigate({ url: url.href, data: form.serialize(), type: 'POST' });
		});

		import('@/lib/components/Shared/HelpIcon.vue').then((helpIcon) => {
			mountVueComponent('help-icon', helpIcon);
		});
	});
}
