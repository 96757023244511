import login from './login';
import passwordForm from '@/modules/clear/password-form';
import passwordReset from './passwordReset';
import registrationForm from '@/modules/clear/registration';
import { Router } from '@/legacy/Router';

Router.get('/authenticate', passwordForm);
Router.get('/:any', login);
Router.get('/password/reset/:any', passwordReset);
Router.get('/register', registrationForm);
Router.get('/register/([a-zA-Z]+)', registrationForm);
Router.get('/register/invited/([a-zA-Z0-9]+)', registrationForm);
Router.get('profile/complete', registrationForm);
Router.get('profile/complete/:any', registrationForm);
