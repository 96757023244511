var pjax = require('@/vendor/pjax/pjax').default;
var $ = require('jquery');
var underscore = require('underscore');
// eslint-disable-next-line @typescript-eslint/naming-convention
var Regions = require('../../lib/regions.js');

module.exports = function () {
	new Regions($('.countries'), function (regions) {
		// Region field replaces state field
		$('input[name="state"]').val('');

		// Insert regions directly after city
		regions.insertAfter($('.form-group-city'));
	});

	// Show discount code input
	$('.apply-discount-code').on('click', function (e) {
		e.preventDefault();
		$('.discount-code-form').toggleClass('hidden');
	});

	// @TODO: Remove when we drop IE8/9 support
	var isIE9OrBelow = function () {
		return /MSIE\s/.test(navigator.userAgent) && parseFloat(navigator.appVersion.split('MSIE')[1]) < 10;
	};

	var updateCart = function (input) {
		let inputId = input.attr('id');

		if (inputId) {
			localStorage.setItem('cart-last-changed-input', inputId);
		}

		var data = $('#cart-form').serialize();

		// @TODO: Remove when we drop IE8/9 support
		var url = isIE9OrBelow() ? '/cart/update' : '/cart';

		pjax.navigate({ url: url, data: data, container: '#pjaxContainer', method: 'POST', preserveScrollPosition: true });
	};

	$(
		'input[name=paymentMethod]:radio, input[name=currency]:radio, input[name=entryFee]:radio, input[name=entrantFee]:radio'
	).on('change', function () {
		updateCart($(this));
	});

	$('#country, .select-region').on(
		'change',
		underscore.debounce(function () {
			updateCart($(this));
		}, 800)
	);

	$('input[name^="entry-fee-member-number"], input[name^="entrant-fee-member-number"]').on('change', function () {
		$('input[name="memberNumber"]').val($(this).val());
	});

	$('#setDiscountCode').click(function (e) {
		e.preventDefault();
		updateCart($(this));
	});

	// Update cart if the value of the VAT number input changes
	var vatNumber = '';

	$('#vat-number').on('focus', function () {
		vatNumber = $(this).val();
	});

	$('#vat-number').on('blur', function () {
		// eslint-disable-next-line eqeqeq
		if ($(this).val() != vatNumber) {
			updateCart($(this));
		}
	});

	$('#process-payment').on('click', function (e) {
		e.disabled = true;
		$('#cart-form').submit();
		return true;
	});

	$('#submit-payment').on('click', function (e) {
		e.preventDefault();
		$(this).prop('disabled', true);
		$('#payment').submit();
		return true;
	});

	let lastChangedInput = localStorage.getItem('cart-last-changed-input');

	if (lastChangedInput) {
		$('#' + lastChangedInput).focus();
	}
};
