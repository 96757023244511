import form from './form';
import imports from './imports';
import index from './index';
import invite from './invite';
import profile from './profile';
import { Router } from '@/legacy/Router';

Router.get('user/import', imports);
Router.get('users/invite', invite);
Router.get('user/:any', form);
Router.get('users/:any', form);
Router.get('user', index);
Router.get('profile', profile);
