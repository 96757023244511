<template>
	<div>
		<auto-scoring-toggle v-if="visibleAutoScoring" :status="!!field.autoScoring" @change="toggleAutoScoring" />
		<auto-tag-toggle v-if="visibleAutoScoring" :status="field.autoTag" @change="toggleAutoTag" />
		<draggable-options
			id="draggable-options"
			:field="field"
			:supported-languages="supportedLanguages"
			:read-only="readOnly"
			:is-wrapped="true"
			@errors="handleErrors"
			@updated="refreshPreview"
		></draggable-options>
	</div>
</template>
<script>
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';
import DraggableOptions from '../../entry-form/Configuration/DraggableOptions';
import AutoScoringToggle from '@/lib/components/Fields/AutoScoringToggle';
import $ from 'jquery';
import AutoTagToggle from '@/lib/components/Fields/AutoTagToggle.vue';

export default {
	components: { AutoTagToggle, DraggableOptions, AutoScoringToggle },
	mixins: [langMixin],
	props: {
		initialField: {
			type: Object,
			required: true,
		},
		supportedLanguages: {
			type: Array,
			required: true,
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
		formSelector: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			visibleAutoScoring: this.initialField.resource === 'Entries',
			field: null,
		};
	},
	methods: {
		handleErrors(hasErrors) {
			$(`${this.formSelector} :submit`).prop('disabled', hasErrors);
		},
		refreshPreview() {
			$('#internal-value-options').trigger('change.preview');
		},
		toggleAutoScoring(value) {
			this.field.autoScoring = value;
		},
		toggleAutoTag(value) {
			this.field.autoTag = value;
		},
	},
	watch: {
		initialField: {
			handler(value) {
				this.field = JSON.parse(JSON.stringify(value));
			},
			immediate: true,
		},
	},
};
</script>
