<template>
	<ul class="submenu">
		<li v-for="item in items" :key="item.name">
			<item
				:id="submenuId(item)"
				:item="item"
				:current-url="currentUrl"
				:current-focus="currentFocus"
				@openShortcutMenu="(item) => $emit('openShortcutMenu', item)"
				@itemSelected="(item) => $emit('itemSelected', item)"
				@focus="(payload) => $emit('focus', payload)"
				@focusNext="(payload) => $emit('focusNext', payload)"
				@focusPrevious="(payload) => $emit('focusPrevious', payload)"
			/>
		</li>
	</ul>
</template>

<script>
import Item from './Item';
import initInterfaceTextPopover from '@/modules/interface-text/interface-text-popovers.js';

export default {
	components: {
		Item,
	},
	props: {
		id: {
			type: String,
			default: 'submenu',
		},
		items: {
			type: Array,
			default: () => [],
		},
		currentUrl: {
			type: String,
			default: '',
		},
		currentFocus: {
			type: Object,
			default: null,
		},
	},
	methods: {
		submenuId(item) {
			return this.id + '-' + item.name;
		},
	},
	mounted() {
		initInterfaceTextPopover();
	},
};
</script>
