<template>
	<div class="scoring-criterion" :class="scoringControl">
		<div class="scoring-control">
			<scoring-control-input v-if="scoringControl === 'input'" :max-score="maximumScore"></scoring-control-input>
			<scoring-control-select
				v-else-if="scoringControl === 'select'"
				:min-score="minimumScore"
				:max-score="maximumScore"
				:increment="scoringIncrement"
			></scoring-control-select>
			<scoring-control-slider
				v-else-if="scoringControl === 'slider'"
				:min-score="minimumScore"
				:max-score="maximumScore"
				:increment="scoringIncrement"
				:label="lang.get('miscellaneous.slider.current_value')"
			></scoring-control-slider>
			<scoring-control-recommendation v-else :recommendations="recommendations"></scoring-control-recommendation>
		</div>
		<slot></slot>
	</div>
</template>

<script>
import ScoringControlInput from './ScoringControlInput';
import ScoringControlSelect from './ScoringControlSelect';
import ScoringControlSlider from './ScoringControlSlider';
import ScoringControlRecommendation from './ScoringControlRecommendation';

export default {
	inject: ['lang'],
	components: {
		ScoringControlInput,
		ScoringControlSelect,
		ScoringControlSlider,
		ScoringControlRecommendation,
	},
	props: {
		scoringControl: {
			type: String,
			default: 'select',
		},
		minimumScore: {
			type: [String, Number],
			default: 1,
		},
		maximumScore: {
			type: [String, Number],
			default: 10,
		},
		scoringIncrement: {
			type: [String, Number],
			default: 1,
		},
		recommendations: {
			type: Array,
			default: () => [],
		},
	},
};
</script>
