import domain from './domain.js';
import integration from './integration';
import languages from './languages';
import registration from './registration';
import { Router } from '@/legacy/Router';
import social from './social';

Router.get('setting/domain', domain);
Router.get('setting/integration', integration);
Router.get('setting/api-v1', integration);
Router.get('setting/registration', registration);
Router.get('setting/social', social);
Router.get('setting/languages', languages);
