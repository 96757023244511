import { Collaborator } from '@/domain/models/Collaborator';
import { CollaboratorsService } from '@/domain/services/Collaboration/Collaborators';
import {
	CollaboratorsListEmitters,
	CollaboratorsListEvents,
} from '@/lib/components/Collaboration/CollaboratorsListModal.events';
import { Ref, ref } from 'vue';
import { trans, Trans } from '@/domain/dao/Translations';

type Props = {
	collaborator: Collaborator;
	canManage: boolean;
	service: CollaboratorsService | null;
};

type View = {
	lang: Trans;
	removeCollaborator: () => void;
	makeOwner: () => void;
	loading: Ref<boolean>;
};

type Ctx = {
	emit: CollaboratorsListEmitters;
};

const collaboratorActionsController = (props: Props, { emit }: Ctx): View => {
	const lang = trans();
	const loading = ref(false);

	const removeCollaborator = async () => {
		if (props.service === null) {
			return;
		}

		loading.value = true;
		const collaborators = await props.service.remove(props.collaborator.slug);
		emit(CollaboratorsListEvents.Updated, collaborators);
		loading.value = false;
	};

	const changeOwner = async () => {
		if (props.service === null) {
			return;
		}

		loading.value = true;
		const collaborators = await props.service.setOwner(props.collaborator.user);
		emit(CollaboratorsListEvents.Updated, collaborators);
		loading.value = false;
	};

	const makeOwner = () => {
		emit(CollaboratorsListEvents.WantsUpdate, {
			prompt: lang.get('collaboration.transfer_ownership_prompt'),
			action: changeOwner,
			cancel: () => {
				loading.value = false;
			},
		});
	};

	return {
		lang,
		removeCollaborator,
		makeOwner,
		loading,
	};
};

export { collaboratorActionsController, Props, View };
