<template>
	<div>
		<div class="row">
			<div class="col-xs-12 col-md-4">
				<div class="panel panel-default">
					<div class="panel-body pbn">
						<div id="labelContainer" class="form-group">
							<div class="panel-title">
								<h4>
									<label for="label">{{ lang.get('scoring-criteria.form.label.label') }}</label>
									<helpIcon :content="lang.get('scoring-criteria.form.label.help')"></helpIcon>
								</h4>
							</div>
							<multilingual
								mode="textarea"
								:resource="resource"
								property="label"
								:supported-languages="supportedLanguages"
								@input="handleMultilingualInput"
							></multilingual>
						</div>

						<div class="form-group form-group-subordinate">
							<label for="title">{{ lang.get('scoring-criteria.form.title.label') }}</label>
							<helpIcon :content="lang.get('scoring-criteria.form.title.help')"></helpIcon>
							<multilingual
								mode="text"
								:resource="resource"
								property="title"
								:supported-languages="supportedLanguages"
								:max-length="32"
								@input="handleMultilingualInput"
							></multilingual>
						</div>

						<div class="form-group form-group-subordinate">
							<label for="shortcode">{{ lang.get('scoring-criteria.form.shortcode.label') }}</label>
							<helpIcon :content="lang.get('scoring-criteria.form.shortcode.help')"></helpIcon>
							<multilingual
								mode="text"
								:resource="resource"
								property="shortcode"
								:supported-languages="supportedLanguages"
								@input="handleMultilingualInput"
							></multilingual>
						</div>

						<div id="hintTextContainer" class="form-group form-group-subordinate">
							<label for="hintText">{{ lang.get('scoring-criteria.form.hint_text.label') }}</label>
							<span class="optional-field"> {{ lang.get('miscellaneous.optional') }}</span>
							<helpIcon :content="lang.get('scoring-criteria.form.hint_text.help')"></helpIcon>
							<multilingual
								mode="markdown"
								markdown-mode="full"
								:resource="resource"
								property="hintText"
								:supported-languages="supportedLanguages"
								mark-down-mode="full"
								@input="handleMultilingualInput"
							></multilingual>
						</div>

						<div id="helpTextContainer" class="form-group form-group-subordinate">
							<label for="helpText">{{ lang.get('scoring-criteria.form.help_text.label') }}</label>
							<span class="optional-field"> {{ lang.get('miscellaneous.optional') }}</span>
							<helpIcon :content="lang.get('scoring-criteria.form.help_text.help')"></helpIcon>
							<multilingual
								mode="textarea"
								:resource="resource"
								property="helpText"
								:supported-languages="supportedLanguages"
								@input="handleMultilingualInput"
							></multilingual>
						</div>
					</div>
				</div>
				<div class="panel panel-default">
					<div class="panel-body pbn">
						<div class="form-group">
							<category-config
								:resource="criterion"
								:categories="categories"
								:select-categories-label="lang.get('scoring-criteria.form.categories_select.label')"
								:all-categories-label="lang.get('scoring-criteria.form.categories_all.label')"
								@input="onCategorySelected"
							>
							</category-config>
						</div>

						<div class="form-group">
							<label for="fieldId">{{ lang.get('scoring-criteria.form.field.label') }}</label>
							<span class="optional-field"> {{ lang.get('miscellaneous.optional') }}</span>
							<helpIcon :content="lang.get('scoring-criteria.form.field.help')"></helpIcon>
							<select id="fieldId" v-model="selectedFieldId" class="form-control" name="fieldId">
								<option>&nbsp;</option>
								<option
									v-for="field in visibleFields"
									:key="`option-field-${field.id}`"
									:value="field.id"
									:selected="field.id === criterion.fieldId"
								>
									{{ field.title }}
								</option>
							</select>

							<div v-if="selectedField && selectedField.conditional" class="alert-warning sticky" style="margin-top: 20px">
								<div class="message">
									{{ lang.get('scoring-criteria.form.field.conditional-warning') }}
								</div>
							</div>
						</div>

						<div v-if="selectedField" class="form-group">
							<div class="checkbox styled">
								<input
									id="checkboxIgnoreEmpty"
									type="checkbox"
									name="ignoreEmpty"
									:checked="criterion.ignoreEmpty && !selectedField.content"
									:disabled="selectedField.content"
								/>
								<label for="checkboxIgnoreEmpty">{{ lang.get('scoring-criteria.form.ignore_empty.label') }}</label>
							</div>
						</div>

						<div class="form-group">
							<label for="order">{{ lang.get('scoring-criteria.form.order.label') }}</label>
							<span class="optional-field"> {{ lang.get('miscellaneous.optional') }}</span>
							<helpIcon :content="lang.get('scoring-criteria.form.order.help')"></helpIcon>
							<!-- eslint-disable-next-line vue/no-mutating-props -->
							<input id="order" v-model="criterion.order" type="number" name="order" min="0" class="form-control" />
						</div>
					</div>
				</div>
			</div>

			<input type="hidden" name="seasonId" :value="criterion.seasonId" />

			<div class="col-xs-12 col-md-4">
				<div v-if="form" class="form-group">
					<label for="formName">
						<span class="af-icons af-icons-lock"></span>
						{{ lang.get('entries.form.season.label') }}
					</label>
					<input id="formName" type="text" class="form-control" :value="formName" disabled />
				</div>

				<div :class="['form-group', { error: hasError('scoresetId') }]">
					<label>
						<span v-if="criterion.hasScores" class="af-icons af-icons-lock"></span>
						{{ lang.get('scoring-criteria.form.score_set.label') }}
						<helpIcon v-if="criterion.hasScores" :content="lang.get('scoring-criteria.form.score_set.has_scores')"></helpIcon>
						<!-- eslint-disable-next-line vue/no-mutating-props -->
						<input v-model="criterion.scoresetId" type="hidden" name="scoresetId" />
					</label>
					<score-set-selector
						id="scoresetId"
						name="scoresetId"
						:score-sets="scoreSets"
						:disabled="criterion.hasScores"
						:current-score-set="scoresetId"
						:redirect-on-select="false"
						@change="(v) => (scoresetId = v)"
					>
					</score-set-selector>
				</div>

				<div :class="['form-group', { error: hasError('scoringControl') }]">
					{{ lang.get('scoring-criteria.form.scoring_control.label') }}
					<div class="radio styled">
						<input id="selectScoringControl" v-model="scoringControl" type="radio" name="scoringControl" value="select" />
						<label for="selectScoringControl">
							{{ lang.get('scoring-criteria.form.select_control.label') }}
						</label>
					</div>
					<div class="radio styled">
						<input id="sliderScoringControl" v-model="scoringControl" type="radio" name="scoringControl" value="slider" />
						<label for="sliderScoringControl">
							{{ lang.get('scoring-criteria.form.slider_control.label') }}
						</label>
					</div>
					<div class="radio styled">
						<input id="inputScoringControl" v-model="scoringControl" type="radio" name="scoringControl" value="input" />
						<label for="inputScoringControl">
							{{ lang.get('scoring-criteria.form.input_control.label') }}
						</label>
					</div>
					<div class="radio styled">
						<input
							id="recommendationScoringControl"
							v-model="scoringControl"
							type="radio"
							name="scoringControl"
							value="recommendation"
						/>
						<label for="recommendationScoringControl">
							{{ lang.get('scoring-criteria.form.recommendation_control.label') }}
						</label>
					</div>
				</div>

				<div v-show="scoringControl === 'recommendation'" :class="['form-group', { error: hasError('recommendations') }]">
					<draggable-options
						:field="criterion"
						:supported-languages="supportedLanguages"
						:default-language="defaultLanguage"
						:language="lang.locale"
						option-property="recommendations"
						translation-name="recommendationLabel"
						option-value="scoring-criteria.form.recommendations.score"
						option-label="scoring-criteria.form.recommendations.label"
						input-name="recommendations"
						:parse-options-function="parseRecommendations"
						:help-icon="false"
						:margin="false"
						:show-scoring-input-by-default="true"
						:show-scoring-input-label="false"
						:show-option-keys="false"
						:is-wrapped="true"
						:set-min-height="false"
						@options-updated="recommendations = $event"
						v-on="$listeners"
					></draggable-options>
				</div>

				<div v-show="scoringControl !== 'recommendation'" :class="['form-group', { error: hasError('maximumScore') }]">
					<label for="maximumScore"> {{ lang.get('scoring-criteria.form.maximum_score.label') }}</label>
					<input
						id="maximumScore"
						type="number"
						:step="maxMinScoreStep"
						:min="minimumScore"
						class="form-control"
						name="maximumScore"
						:value="maximumScore"
						required
						@blur="onMaximumScoreInput"
						@input="onMaximumScoreInput"
					/>
				</div>

				<div v-show="scoringControl !== 'recommendation'" :class="['form-group', { error: hasError('minimumScore') }]">
					<label for="minimumScore"> {{ lang.get('scoring-criteria.form.minimum_score.label') }}</label>
					<input
						id="minimumScore"
						type="number"
						:step="maxMinScoreStep"
						:min="minimumScore"
						class="form-control"
						name="minimumScore"
						:value="minimumScore"
						required
						@blur="onMinimumScoreInput"
						@input="onMinimumScoreInput"
					/>
				</div>

				<div :class="['form-group', { error: hasError('weight') }]">
					<label for="weight"> {{ lang.get('scoring-criteria.form.weight.label') }}</label>
					<helpIcon :content="lang.get('scoring-criteria.form.weight.help')"></helpIcon>
					<input id="weight" v-model="weight" name="weight" min="0" class="form-control" />
				</div>

				<div
					v-show="scoringControl === 'input'"
					id="scoring-precision"
					:class="['form-group', { error: hasError('scoringPrecision') }]"
				>
					{{ lang.get('scoring-criteria.form.scoring_precision.label') }}
					<div class="radio styled">
						<input id="integerPrecision" v-model="scoringPrecision" type="radio" name="scoringPrecision" :value="0" />
						<label for="integerPrecision">
							{{ lang.get('scoring-criteria.form.scoring_precision.integer.label') }}
						</label>
					</div>
					<div class="radio styled">
						<input id="oneDecimalPrecision" v-model="scoringPrecision" type="radio" name="scoringPrecision" :value="1" />
						<label for="oneDecimalPrecision">
							{{ lang.get('scoring-criteria.form.scoring_precision.one-decimal.label') }}
						</label>
					</div>
					<div class="radio styled">
						<input id="twoDecimalsPrecision" v-model="scoringPrecision" type="radio" name="scoringPrecision" :value="2" />
						<label for="twoDecimalsPrecision">
							{{ lang.get('scoring-criteria.form.scoring_precision.two-decimals.label') }}
						</label>
					</div>
				</div>

				<div
					v-show="scoringControl !== 'input' && scoringControl !== 'recommendation'"
					:class="['form-group', { error: hasError('scoringIncrement') }]"
				>
					<label for="scoringIncrement">{{ lang.get('scoring-criteria.form.scoring_increment.label') }}</label>
					<input
						id="scoringIncrement"
						v-model="scoringIncrement"
						type="text"
						maxlength="5"
						name="scoringIncrement"
						class="form-control"
					/>
				</div>

				<div class="panel panel-default">
					<div class="panel-body pbn">
						<div class="panel-title">
							<h4>
								<label for="label">{{ lang.get('score-set.titles.commenting') }}</label>
							</h4>
						</div>
						<div class="checkbox styled">
							<input
								id="comments-allowed"
								v-model="commentsAllowed"
								type="checkbox"
								name="commentsAllowed"
								value="1"
								@change="onCommentsAllowed"
							/>
							<label for="comments-allowed">{{ lang.get('scoring-criteria.form.comment_scores.label') }}</label>
						</div>
						<div class="checkbox styled indent-option">
							<input
								id="comments-required"
								v-model="commentsRequired"
								:checked="commentsRequired"
								:disabled="!commentsAllowed"
								type="checkbox"
								name="commentsRequired"
								value="1"
							/>
							<label for="comments-required">{{ lang.get('scoring-criteria.form.comment_scores_required.label') }}</label>
						</div>
					</div>
				</div>

				<input v-if="criterion.id > 0" type="hidden" name="updatedAt" :value="criterion.updatedAt" />
			</div>

			<div class="col-xs-12 col-md-4">
				<component-preview :heading="lang.get('scoring-criteria.preview.heading')">
					<div v-if="cannotGeneratePreview" class="form-group">
						{{ lang.get('fields.preview.error') }}
					</div>
					<div v-else class="form-group">
						<h3 v-if="label.length > 0">
							{{ label }}
							<helpIcon v-if="helpText" :content="helpText"></helpIcon>
						</h3>
						<div class="row">
							<div class="col-xs-12">
								<scoring-criterion
									:scoring-control="scoringControl"
									:minimum-score="minimumScore"
									:maximum-score="maximumScore"
									:recommendations="recommendations"
									:scoring-increment="parseFloat(scoringIncrement) || 1"
								>
									<div v-if="hintText" class="scoring-hint-text">
										<!-- eslint-disable-next-line vue/no-v-html -->
										<p v-html="sanitizeMarkdown(hintText)"></p>
									</div>
								</scoring-criterion>
							</div>
						</div>
					</div>
				</component-preview>
			</div>
		</div>
	</div>
</template>

<script>
import ScoreSetSelector from '@/lib/components/Selectors/ScoreSetSelector';
import Multilingual from '@/lib/components/Translations/Multilingual';
import HelpIcon from '@/lib/components/Shared/HelpIcon';
import CategoryConfig from '@/lib/components/Categories/CategoryConfig';
import { mapGetters, mapState } from 'vuex';
import ComponentPreview from '@/lib/components/ComponentPreview';
import ScoringCriterion from '@/lib/components/ScoringCriteria/ScoringCriterion';
import { sanitize } from '@/lib/utils.js';
import marked from 'marked';
import FieldCopier from '@/lib/field-copier';
import { parseRecommendations } from '@/modules/scoring-criteria/components/recommendationsHelper';
import DraggableOptions from '@/modules/entry-form/Configuration/DraggableOptions.vue';

export default {
	components: {
		DraggableOptions,
		ScoreSetSelector,
		CategoryConfig,
		Multilingual,
		HelpIcon,
		ComponentPreview,
		ScoringCriterion,
	},
	inject: ['lang'],
	props: {
		scoreSets: {
			type: Array,
			required: true,
		},
		categories: {
			type: Array,
			required: true,
		},
		criterion: {
			type: Object,
			required: true,
		},
		supportedLanguages: {
			type: Array,
			required: true,
		},
		fields: {
			type: Array,
			required: true,
		},
		form: {
			type: Object,
			default: null,
		},
	},
	data() {
		const translations = this.criterion.translated[this.lang.locale];

		let obj = {
			categoryOption: this.criterion.categoryOption,
			scoresetId: this.criterion.scoresetId,
			scoringControl: this.criterion.scoringControl || 'select',
			selectedFieldId: this.criterion.fieldId,
			selectedCategories: this.criterion.categories,
			maximumScore: this.preferAsInt(this.criterion.maximumScore ? this.criterion.maximumScore : 10),
			minimumScore: this.criterion.minimumScore ? this.criterion.minimumScore : 0,
			scoringIncrement: this.preferAsInt(this.criterion.scoringIncrement ? this.criterion.scoringIncrement : 1),
			scoringPrecision: this.criterion.scoringPrecision ? this.criterion.scoringPrecision : 0,
			recommendations: this.criterion.recommendations ? parseRecommendations(this.criterion, this.supportedLanguages) : [],
			weight: this.criterion.weight ? this.criterion.weight : 1,
			commentsAllowed: this.criterion.commentsAllowed,
			commentsRequired: this.criterion.commentsRequired,
			label: translations !== undefined ? translations.label : '',
			helpText: translations !== undefined ? translations.helpText : '',
			hintText: translations !== undefined ? translations.hintText : '',
		};

		const formKeys = Object.keys(obj);

		obj.resource = this.criterion;

		return { ...obj, formKeys: formKeys };
	},
	computed: {
		...mapState('global', ['defaultLanguage']),
		...mapGetters('validation', ['validationErrors', 'hasError']),

		visibleFields() {
			return Object.values(this.fields).filter((field) => this.showField(field));
		},

		selectedField() {
			return this.fields.find((field) => field.id === this.selectedFieldId);
		},

		cannotGeneratePreview() {
			return (
				this.scoringControl === 'select' &&
				(this.maximumScore - this.minimumScore) / (parseFloat(this.scoringIncrement) || 1) > 1000
			);
		},

		formName() {
			return this.form.name ?? '';
		},

		isScoreIncrementAnInt() {
			return this.scoringIncrement === parseInt(this.scoringIncrement);
		},

		maxMinScoreStep() {
			return this.scoringControl === 'select' || this.scoringControl === 'slider' ? this.scoringIncrement || 1 : null;
		},
	},
	created() {
		this.marked = marked;
		this.marked.setOptions({ breaks: true });
	},
	mounted() {
		this.formKeys.forEach((key) => {
			this.$watch(key, (newVal, oldVal) => {
				if (newVal !== oldVal) {
					this.$emit('input', key, newVal);
				}
			});
		});

		const titleCopier = new FieldCopier();
		const shortCodeCopier = new FieldCopier();

		titleCopier.setup('form.scoring-criteria-form-new', '.multilingual-label', '.multilingual-title', 'label', 'title');
		shortCodeCopier.setup(
			'form.scoring-criteria-form-new',
			'.multilingual-label',
			'.multilingual-title',
			'label',
			'shortcode'
		);

		this.refreshFormattingOfMinScore();
		this.refreshFormattingOfMaxScore();
	},
	methods: {
		parseRecommendations,
		showField(field) {
			if (this.categoryOption === 'all') {
				return field.categories === true;
			} else {
				return (
					field.categories === true ||
					this.selectedCategories.length === 0 ||
					this.selectedCategories.every((c) => field.categories.includes(c))
				);
			}
		},
		onCategorySelected(data) {
			this.categoryOption = data.categoryOption;
			if (data.categoryOption === 'select') {
				this.selectedCategories = data.categories;
			}
		},
		onMinimumScoreInput(event) {
			this.refreshFormattingOfMinScore(event.target.value);
			this.$emit('input', this.minimumScore);
		},
		onMaximumScoreInput(event) {
			this.refreshFormattingOfMaxScore(event.target.value);
			this.$emit('input', this.maximumScore);
		},
		onCommentsAllowed(event) {
			if (!event.target.checked) this.commentsRequired = false;
		},
		refreshFormattingOfMinScore(newValue = null) {
			this.minimumScore = this.preferAsInt(newValue !== null ? newValue : this.minimumScore);
		},
		refreshFormattingOfMaxScore(newValue = null) {
			this.maximumScore = this.preferAsInt(newValue !== null ? newValue : this.maximumScore);
		},
		handleMultilingualInput(e, language, property, value) {
			this.resource.translated[language][property] = value;
			this.$emit('input', 'translated.' + property + '.' + language, value);

			if (language === this.lang.locale) {
				switch (property) {
					case 'label':
						this.label = value;
						break;
					case 'hintText':
						this.hintText = value;
						break;
					case 'helpText':
						this.helpText = value;
						break;
					default:
				}
			}
		},
		sanitizeMarkdown(markdown) {
			return sanitize(this.marked(markdown));
		},
		preferAsInt(value) {
			if (value === '') {
				return undefined;
			}

			if (this.isScoreIncrementAnInt) {
				return parseInt(value);
			}

			return value * 1;
		},
	},
};
</script>
