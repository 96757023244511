const initAlertValidation = () => {
	// Wait for Vue to init components that may contain tabs
	setTimeout(() => {
		const checkOtherTabs = document.getElementById('check-other-tabs');
		if (checkOtherTabs) {
			const numOfTabs =
				document.querySelectorAll('ul[role="tablist"] li[role="tab"]').length +
				document.querySelectorAll('.tabs ul.tabs li[data-tab]').length;

			if (numOfTabs > 1) {
				checkOtherTabs.style.display = 'block';
			}
		}
	}, 100);
};

export default initAlertValidation;
