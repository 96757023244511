var pjax = require('@/vendor/pjax/pjax').default;
const Storage = require('../storage');
const $ = require('jquery');
const _ = require('underscore');

/**
 * Tab library to help manage the constious interactions for tabs.
 */
module.exports = function() {
  const self = this;
  const storage = new Storage();

  // Store the name of the active tab in a cookie
  const createActiveTabCookie = function(tabName) {
    storage.createCookie('active_tab', tabName, 1);
  };

  const removeActiveTabCookie = function() {
    storage.createCookie('active_tab', null, -1);
  };

  // Removes 'tab=tab.view.name' from http params and also cleans up trailing '?' if needed
  const removeActiveTabReferenceFromUrl = function() {
    // `keyword` occurrence in the url means it's a search filter, don't remove the `tab` part.
    if (window.location.href.indexOf('tab=') !== -1 && window.location.href.indexOf('keywords=') === -1) {
      // Url with Regex101 tests: https://regex101.com/r/6sw44p/2
      let newUrl = window.location.href.replace(/([?&])(tab=[^\\&=]*)/i, '$1');

      if (newUrl.endsWith('?')) {
        newUrl = newUrl.slice(0, -1);
      }

      window.history.replaceState({}, document.title, newUrl);
    }
  };

  // Add a shadow to the tabs when element is on top of the page
  const addShadowToTab = function() {
    const tabContainer = document.getElementById('tabsLegacy');

    if (tabContainer) {
      const distanceToTop = tabContainer.getBoundingClientRect().top;

      distanceToTop <= 0
        ? tabContainer.classList.add('tabs-legacy-shadow')
        : tabContainer.classList.remove('tabs-legacy-shadow');
    }
  };

  // Sets the active tab
  this.setActive = function($tabButton) {
    const tabStyle = $('.tabular-system').data('type');
    const tabButtonId = $tabButton.attr('id');

    if (tabStyle === 'wizard') {
      $('#tabsLegacy button')
        .addClass('disabled')
        .removeClass('active'); // deactivate all tabs

      $tabButton.addClass('active').removeClass('disabled');
    } else {
      // remove class to all tab buttons
      $('#tabsLegacy button')
        .removeClass('active')
        .attr('aria-selected', 'false');

      // add class to current clicked one
      $tabButton.addClass('active').attr('aria-selected', 'true');

      $(document).trigger('tab.activated', $tabButton.attr('id'));
    }

    $('.tab-content').removeClass('active'); // activate selected tab
    $('#' + tabButtonId + 'Content').addClass('active'); // activate tab content;

    createActiveTabCookie($tabButton.data('tab'));

    removeActiveTabReferenceFromUrl();
  };

  // Activate the requested tab
  this.click = function(e) {
    e.preventDefault();
    const $tabButton = $(this);

    if (!$tabButton.hasClass('disabled')) {
      self.setActive($tabButton);
      $('body').trigger('tabchange', [this]);
    }
  };

  // Same as click, but simply cycles through to the next tab
  this.next = function() {
    const tab = $('#tabsLegacy button.active');
    const next = tab.next();

    if (next.length) {
      self.setActive(next);
    }
  };

  // Checks to see if there is another tab to move to
  this.hasNext = function() {
    const tab = $('#tabsLegacy button.active');
    const next = tab.next();

    return next.length;
  };

  // Invokes specific tab, if the slug is provided via the url.
  // Uses the native getElementById method that can safely accept malformed ids without throwing a SyntaxError.
  this.checkHashTab = function() {
    const hash = window.location.hash;
    const tab = document.getElementById(hash.replace('#', '') + '-Tab');

    if (tab) {
      $(tab).trigger('click');
    }
  };

  // initialize aria-selected
  $('#tabsLegacy button').attr('aria-selected', 'false');
  $('#tabsLegacy button.active').attr('aria-selected', 'true');

  // When we initialise tabular let's setup our tab bindings
  $('body').on('click', '#tabsLegacy button', this.click);
  $('body').on('click', '.next-tab', this.click);

  self.checkHashTab();

  // Remove active tab cookie on page reloads
  $(document).on('ready', function() {
    if (pjax.options() === undefined) {
      removeActiveTabCookie();
    }

    window.addEventListener(
      'scroll',
      _.throttle(() => addShadowToTab(), 100)
    );
  });

  removeActiveTabReferenceFromUrl();
  $(document).on('ready pjax:success', function() {
    removeActiveTabReferenceFromUrl();
  });
};
