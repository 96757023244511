<template>
	<a tabindex="0" :class="customClass" @click.stop.prevent="openPayments" v-text="label"></a>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	name: 'AllocationPaymentsLink',

	props: {
		label: {
			type: String,
			required: true,
		},
		allocation: {
			type: Object,
			required: true,
		},
		currency: {
			type: String,
			required: true,
		},
		editOnlyAllocationPayment: {
			type: Object,
			default: () => {},
			required: false,
		},
		customClass: {
			type: String,
			default: null,
		},
	},

	methods: {
		...mapActions('allocationPayments', ['openModalWithAllocation', 'openModalWithAllocationAndLock']),
		openPayments() {
			this.$emit('openModal');
			// eslint-disable-next-line vue/no-mutating-props
			this.allocation.currency = this.currency;
			if (Object.keys(this.editOnlyAllocationPayment).length > 0) {
				this.openModalWithAllocationAndLock({
					allocation: this.allocation,
					editOnlyAllocationPayment: this.editOnlyAllocationPayment,
				});
			} else {
				this.openModalWithAllocation(this.allocation);
			}
		},
	},
};
</script>

<style scoped></style>
