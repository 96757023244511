import { Defactorize } from '@/domain/utils/UtilityTypes';
import { onBeforeMount, onBeforeUnmount, onMounted, provide, watch } from 'vue';

const vueHookFactories = {
	onBeforeMount: () => onBeforeMount,
	onMounted: () => onMounted,
	onBeforeUnmount: () => onBeforeUnmount,
	provide: () => provide,
	onBeforeAppUnload: () => (cb: () => void) => window.addEventListener('beforeunload', cb),
	onWatch: () => watch,
};

type VueHooks = Defactorize<typeof vueHookFactories>;

export { type VueHooks, vueHookFactories };
