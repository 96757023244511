import $ from 'jquery';
import { ModalEmitters } from '@/lib/components/ListActions/composables/useModal.events';
import { trans } from '@/services/global/translations.interface';
import { useModal } from '@/lib/components/ListActions/composables/useModal';
import { ref, Ref } from 'vue';

type Props = {
	scoreDataUrl: string;
};

type Ctx = {
	emit: ModalEmitters;
};

type View = {
	lang: typeof trans;
	name: string;
	content: Ref<string | null>;
	message: Ref<string | null>;
	loading: Ref<boolean>;
	url: Ref<string>;
	lastLoadedUrl: Ref<string | null>;
	showModal: Ref<boolean>;
	loadContent: () => void;
	handleLinkClick: (event: MouseEvent) => void;
	printContent: () => void;
	toggle: () => void;
	modalClosed: () => void;
};

const scoreMatrixController = (props: Props, { emit }: Ctx): View => {
	const name = 'score-matrix';
	const { showModal, toggle, modalClosed } = useModal(name, emit);
	const content = ref<string | null>(null);
	const message = ref<string | null>(null);
	const url = ref(props.scoreDataUrl);
	const lastLoadedUrl = ref<string | null>(null);
	const loading = ref<boolean>(false);

	const handleLinkClick = async (event: MouseEvent) => {
		const target = event.target as HTMLElement;
		if (target.tagName === 'A' && typeof target.closest === 'function') {
			const href = target.getAttribute('href');
			if (target.closest('thead')) {
				event.preventDefault();
				const href = target.getAttribute('href');
				if (href) {
					url.value = href;
					await loadContent();
				}
			} else if (href) {
				event.preventDefault();
				showModal.value = false;
				$.pjax({ url: href, container: '#pjaxContainer', method: 'GET' });
			}
		}
	};

	const loadContent = async () => {
		if (loading.value || lastLoadedUrl.value === url.value) {
			return;
		}

		loading.value = true;

		try {
			const response = await fetch(url.value);
			if (!response.ok) {
				throw new Error('Network response failed');
			}

			content.value = await response.text();
			if (typeof document !== 'undefined') {
				const tempDiv = document.createElement('div');
				tempDiv.innerHTML = content.value;
				message.value = tempDiv?.querySelector('data-table')?.getAttribute('message') || null;
			}

			lastLoadedUrl.value = url.value;
		} catch (error) {
			console.error('Failed to load score matrix:', error);
			content.value = trans.get('judging.score_matrix.errors.loading');
		} finally {
			loading.value = false;
		}
	};

	const printContent = () => {
		window.print();
	};

	return {
		lang: trans,
		name,
		content,
		message,
		loading,
		url,
		lastLoadedUrl,
		showModal,
		loadContent,
		handleLinkClick,
		printContent,
		modalClosed,
		toggle,
	};
};

export { scoreMatrixController, View, Props };
