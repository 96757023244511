import { render, staticRenderFns } from "./TableFieldConfiguratorColumn.vue?vue&type=template&id=bdd8755c&scoped=true"
import script from "./TableFieldConfiguratorColumn.vue?vue&type=script&lang=js"
export * from "./TableFieldConfiguratorColumn.vue?vue&type=script&lang=js"
import style0 from "./TableFieldConfiguratorColumn.vue?vue&type=style&index=0&id=bdd8755c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdd8755c",
  null
  
)

export default component.exports