<template>
	<div v-resize="onResize" class="marker-action">
		<list-action-form
			ref="form"
			:ids="ids"
			:labels="labels"
			:route="grantStatusRoute"
			:method="method"
			:show-submit="mode === 'bulk'"
			@submitted="toggle"
		>
			<input type="hidden" name="grantStatus" :value="grantStatus" />
			<portal v-if="mode === 'bulk'" to="grant-status-selector">
				<div class="grant-status-selector-modal">
					<modal
						v-model="showModal"
						:close-button-label="labels.cancel"
						:confirm-button-label="labels.confirm"
						:confirm-disabled="false"
						:confirm-on-enter="false"
						:header="false"
						:header-close-button="true"
						:title="labels.label"
						@closed="modalClosed"
						@confirmed="submit"
					>
						<close-icon slot="before-content" @click.prevent="toggle"></close-icon>
						<div :key="name">
							<label for="grantStatusSelector">{{ labels.label }}</label>
							<select-field
								id="grantStatusSelector"
								:aria-label="labels.label"
								:disabled="disabled"
								:empty-option="mode !== ''"
								:items="grantStatuses"
								id-property="slug"
								name="grantStatuses"
								value-property="name"
								@selected="(name, slug) => (grantStatus = slug)"
							/>
						</div>
					</modal>
				</div>
			</portal>
			<div v-else class="inline-action-single-grant-status">
				<span v-if="isHidden" class="grant-status-selector">{{ selectedStatusLabel }}</span>
				<select-field
					v-else
					name="grantStatuses"
					:items="grantStatuses"
					:empty-option="true"
					id-property="slug"
					:aria-label="labels.label"
					value-property="name"
					:value="grantStatus"
					:disabled="disabled"
					@selected="onSelect"
				/>
			</div>
		</list-action-form>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import ListAction from './ListAction.vue';
import { Modal, SelectField } from 'vue-bootstrap';
import resize from '@/lib/directives/resize.js';
import CloseIcon from '@/lib/components/ListActions/Partials/CloseIcon.vue';
import { route } from '@/lib/utils';
import { useModal } from '@/services/global/modal.interface';

export default defineComponent({
	components: {
		CloseIcon,
		SelectField,
		Modal,
	},
	extends: ListAction,
	directives: {
		resize,
	},
	props: {
		grantStatuses: {
			type: Array,
			required: true,
		},
		mode: {
			type: String,
			default: 'bulk',
		},
		selectedStatus: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		name: {
			type: String,
			default: '',
		},
	},
	setup(props, { emit }) {
		const { showModal, toggle, modalClosed } = useModal(props.name, emit);

		return { showModal, modalClosed, toggle };
	},
	data() {
		return {
			grantStatus: this.selectedStatus,
			isHidden: true,
		};
	},
	computed: {
		grantStatusRoute() {
			return route(this.route, { grantStatus: this.grantStatus });
		},
		selectedStatusLabel() {
			const status = this.grantStatuses.find((grantStatus) => grantStatus.slug === this.selectedStatus);
			return status ? status.name : '—';
		},
	},
	methods: {
		onSelect(el, slug) {
			if (this.grantStatus !== slug) {
				this.grantStatus = slug;
				this.$nextTick(() => this.submit());
			}
		},
		onResize({ width }) {
			// This condition checks if the GrantStatusSelector is positioned beneath a hidden datatable panel,
			// typically concealed under the "+" icon. In such cases, only the status text is visible as no component functions are accessible.
			this.isHidden = !width;
		},
	},
});
</script>

<style scoped>
.inline-action-single-grant-status {
	max-width: 250px;
}
</style>
