import { EventEmitters } from '@/domain/utils/Events';

enum ModalEvents {
	Reveal = 'reveal',
}

type ModalEmitters = EventEmitters<{
	[ModalEvents.Reveal]: (name: string | null) => void;
}>;

export { ModalEvents, ModalEmitters };
