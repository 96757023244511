import $ from 'jquery';
import { EventEmitters } from '@/domain/utils/Events';
import pjax from '@/vendor/pjax/pjax';
import { useContainer } from '@/domain/services/Container';
import { VueHooks } from '@/domain/services/VueHooks';
import { computed, ComputedRef, Ref, ref } from 'vue';
import { getUrlParameter, setUrlParameter } from '@/lib/url';

type Tab = {
	id: string;
	name: string;
	disabled?: boolean;
	active?: boolean;
};

type Props = {
	tabs: Tab[];
	whenDisabledRedirectTo?: string;
	stickySelector?: string;
};

type View = {
	currentTab: Ref<Tab>;
	selectTab: (newTabId: Tab['id'], pushState?: boolean) => void;
	liveTabs: ComputedRef<Tab[]>;
	navigation: ComputedRef<{
		selectNext: () => void;
		selectPrev: () => void;
		hasNextTab: ComputedRef<boolean>;
		hasPrevTab: ComputedRef<boolean>;
	}>;
};

enum TabbedContentEvents {
	TabSelected = 'tabSelected',
}

type TabbedContentEmitters = EventEmitters<{
	[TabbedContentEvents.TabSelected]: (tab: Tab) => void;
}>;

type Context = {
	emit: TabbedContentEmitters;
};

const tabbedContentController = (props: Props, { emit }: Context) => {
	const { onBeforeMount, onBeforeUnmount, onMounted } = useContainer<VueHooks>();

	const liveTabs = computed(() =>
		props.tabs.map((tab) => ({
			id: tab.id,
			name: tab.name,
			disabled: tab.disabled || false,
			active: tab.active || false,
		}))
	);

	const currentTab = ref(liveTabs.value.find((tab) => tab.id === getUrlParameter(window.location.href, 'vtab')));

	const defaultTab = computed(
		() => liveTabs.value.find((tab) => tab.active && !tab.disabled) || liveTabs.value.find((tab) => !tab.disabled)
	);

	const activeTabIndex = computed(() => liveTabs.value.findIndex((tab) => tab.id === currentTab.value?.id));

	const nextTab = computed(() => liveTabs.value.slice(activeTabIndex.value + 1).find((tab) => !tab.disabled));
	const prevTab = computed(() =>
		liveTabs.value
			.slice(0, activeTabIndex.value)
			.reverse()
			.find((tab) => !tab.disabled)
	);

	const hasNextTab = computed(() => !!nextTab.value);
	const hasPrevTab = computed(() => !!prevTab.value);

	const navigation = computed(() => ({
		selectNext: onNextTab,
		selectPrev: onPrevTab,
		hasNextTab,
		hasPrevTab,
	}));

	onBeforeMount(() => {
		const initialTab = currentTab.value?.id || defaultTab.value?.id;
		if (initialTab) selectTab(initialTab, false);
	});

	onMounted(() => {
		$(window).on('popstate.pjax', handlePopstate);
	});

	onBeforeUnmount(() => {
		$(window).off('popstate.pjax', handlePopstate);
	});

	const onNextTab = () => {
		if (hasNextTab.value && nextTab.value) selectTab(nextTab.value.id);
	};

	const onPrevTab = () => {
		if (hasPrevTab.value && prevTab.value) selectTab(prevTab.value.id);
	};

	const selectTab = (newTabId: Tab['id'], pushState = true) => {
		const tab = liveTabs.value.find((tab) => tab.id === newTabId);
		if (!tab) return;

		if (tab.disabled) {
			if (props.whenDisabledRedirectTo) {
				window.location.href = props.whenDisabledRedirectTo;
			}

			return;
		}

		if (!newTabId || currentTab.value?.id === newTabId) return;

		if (pushState) {
			window.history.pushState(
				{ ...pjax.state(), tab: tab.id },
				'',
				setUrlParameter(window.location.href, 'vtab', tab.id)
			);
		} else {
			window.history.replaceState(
				{ ...pjax.state(), tab: tab.id },
				'',
				setUrlParameter(window.location.href, 'vtab', tab.id)
			);
		}

		currentTab.value = { ...tab };
		emit(TabbedContentEvents.TabSelected, { ...tab });
	};

	const handlePopstate = () => {
		const previousPath = pjax.state()?.url;
		const newPath = window.location.pathname;

		// If the user left the component due to a popstate event, reload the page since the URL has already changed
		if (previousPath !== newPath) {
			pjax.reload();
			return;
		}

		const newTabId = getUrlParameter(window.location.href, 'vtab');
		if (newTabId !== currentTab.value?.id) selectTab(newTabId, false);
	};

	return {
		currentTab,
		selectTab,
		liveTabs,
		navigation,
	};
};

export { tabbedContentController, Props, View };
