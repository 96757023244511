<template>
	<div>
		{{ item.name }}
		<a v-if="item.assignmentsCount > 0" @click.prevent="onClick">
			{{ item.assignmentsCount }}
		</a>
		<span v-else>{{ item.assignmentsCount }}</span>
	</div>
</template>
<script>
export default {
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	methods: {
		onClick() {
			this.$emit('clicked', this.item.id);
		},
	},
};
</script>
