import { CollaboratorPrivilege } from '@/domain/models/Collaborator';
import { CollaboratorsService } from '@/domain/services/Collaboration/Collaborators';
import { featureEnabled } from '@/domain/dao/Features';
import { InviteCollaboratorsForm } from '@/domain/services/Collaboration/Api';
import { useContainer } from '@/domain/services/Container';
import { VueHooks } from '@/domain/services/VueHooks';
import { collaborationUIBus, CollaborationUISignals } from '@/domain/signals/Collaboration';
import { computed, ComputedRef, ref, Ref } from 'vue';
import { trans, Trans } from '@/domain/dao/Translations';

type Props = unknown;

type View = {
	visible: boolean;
	loading: Ref<boolean>;
	ready: ComputedRef<boolean>;
	lang: Trans;
	isModalOpen: Ref<boolean>;
	onClose: () => void;
	form: Ref<InviteCollaboratorsForm>;
	formCallbacks: {
		onEmails: (emails: string[]) => void;
		onPrivilege: (name: string, privilege: CollaboratorPrivilege) => void;
	};
	onInvite: () => void;
	privileges: {
		id: CollaboratorPrivilege;
		name: string;
	}[];
	componentKey: Ref<number>;
};

const collaboratorsInviteModalController = (): View => {
	const { provide, onWatch } = useContainer<VueHooks>();

	let collaboratorsService: CollaboratorsService | null = null;

	const lang = trans();

	const form = ref<InviteCollaboratorsForm>({
		emails: [],
		privilege: CollaboratorPrivilege.VIEWER,
		message: '',
	});

	const ready = computed(() => loading.value === false && form.value.emails.length > 0 && form.value.privilege !== null);

	const formCallbacks = {
		onEmails: (emails: string[]) => {
			form.value.emails = emails;
		},
		onPrivilege: (name: string, privilege: CollaboratorPrivilege) => {
			form.value.privilege = privilege;
		},
	};

	const isModalOpen = ref(false);

	const loading = ref(false);

	const componentKey = ref(0);

	collaborationUIBus.on(CollaborationUISignals.OPEN_INVITER, (cService) => {
		if (collaboratorsService !== null) {
			collaboratorsService.destroy();
		}

		collaboratorsService = cService;
		isModalOpen.value = true;
	});

	const onClose = () => {
		isModalOpen.value = false;
	};

	const clearForm = () => {
		form.value = {
			emails: [],
			privilege: CollaboratorPrivilege.VIEWER,
			message: '',
		};

		componentKey.value += 1;
	};

	const onInvite = async () => {
		if (collaboratorsService === null) {
			return;
		}

		loading.value = true;
		try {
			const collaborators = await collaboratorsService.invite(form.value);
			collaboratorsService.update(collaborators);
			isModalOpen.value = false;
		} catch (e) {
			/* empty */
		} finally {
			loading.value = false;
		}
	};

	const privileges = Object.values(CollaboratorPrivilege).map((privilege: CollaboratorPrivilege) => ({
		id: privilege,
		name: trans().get(`collaboration.privileges.${privilege}`),
	}));

	onWatch(isModalOpen, (newVal) => {
		if (newVal) clearForm();
	});

	provide('lang', lang);

	return {
		lang,
		visible: featureEnabled('collaboration'),
		loading,
		ready,
		isModalOpen,
		onClose,
		form,
		formCallbacks,
		onInvite,
		privileges,
		componentKey,
	};
};

export { collaboratorsInviteModalController, Props, View };
