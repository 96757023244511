<template>
	<div>
		<div v-if="!useCheckbox" class="onoffswitch">
			<input type="hidden" :name="name" :value="value" />
			<input
				:id="id"
				:class="['onoffswitch-checkbox', { checked: isChecked }]"
				:name="name"
				type="checkbox"
				:value="value"
				:checked="isChecked ? 'checked' : ''"
				:disabled="disabled"
				:aria-label="ariaLabel"
				@click="toggle"
			/>
			<label class="onoffswitch-label" :for="id">
				<span :class="cssClass" :data-on-label="onLabel" :data-off-label="offLabel"></span>
				<span class="onoffswitch-switch"></span>
			</label>
		</div>

		<div v-else>
			<checkbox :id="id" :selected="isChecked" :disabled="disabled" :name="name" :aria-label="ariaLabel" @change="toggle">
				<template #label>
					{{ ariaLabel }}
				</template>
			</checkbox>
		</div>
	</div>
</template>

<script>
import { Checkbox } from 'vue-bootstrap';
import pjax from '@/vendor/pjax/pjax';

const loader = require('../../loader');
const urlLib = require('../../url');

export default {
	components: {
		Checkbox,
	},
	props: {
		href: {
			type: String,
			default: null,
		},
		name: {
			type: String,
			required: true,
		},
		id: {
			type: String,
			required: true,
		},
		checked: {
			type: Boolean,
			default: false,
		},
		onLabel: {
			type: String,
			required: true,
		},
		offLabel: {
			type: String,
			required: true,
		},
		ariaLabel: {
			type: String,
			required: true,
		},
		useInnerState: {
			type: Boolean,
			default: false,
			description: 'When true, state is stored initially. If false, `checked` prop is used to determine the state.',
		},
		bindToHttpParameter: {
			type: String,
			default: null,
			description: 'When switch is toggled, pjax-reload the page with the new http parameter value attached to url.',
		},
		applyPageFix: {
			type: Boolean,
			default: false,
			description: 'When reloading the page, make sure to set http `page` parameter to 1.',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		useCheckbox: {
			type: Boolean,
			default: false,
			description: 'When false, a checkbox is shown instead of a toggle switch.',
		},
	},
	data() {
		return {
			state: this.useInnerState ? this.checked : 'unused',
		};
	},
	computed: {
		cssClass() {
			return this.disabled ? 'onoffswitch-inner disabled' : 'onoffswitch-inner';
		},
		isChecked() {
			if (this.useInnerState) {
				return !!this.state;
			}

			return !!this.checked;
		},
		value() {
			return (this.useInnerState ? this.state : this.checked) ? 1 : 0;
		},
		toggledValue() {
			return (this.useInnerState ? this.state : this.checked) ? 0 : 1;
		},
	},
	methods: {
		toggle() {
			let newUrl;

			if (this.useInnerState) {
				this.state = this.state ? 0 : 1;
			}

			if (this.bindToHttpParameter) {
				newUrl = urlLib.setUrlParameter(document.location.href, this.bindToHttpParameter, this.value ? '0' : '1');
				if (this.applyPageFix) {
					newUrl = urlLib.setUrlParameter(newUrl, 'page', 1);
				}
			} else {
				newUrl = this.href;
			}

			if (newUrl) {
				loader.showLoader();

				pjax.navigate({
					url: newUrl,
				});
			} else {
				this.$emit('change', this.value);
			}
		},
	},
};
</script>
