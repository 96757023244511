import { render, staticRenderFns } from "./FormBox.vue?vue&type=template&id=70afca7e&scoped=true"
import script from "./FormBox.vue?vue&type=script&lang=js"
export * from "./FormBox.vue?vue&type=script&lang=js"
import style0 from "./FormBox.vue?vue&type=style&index=0&id=70afca7e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70afca7e",
  null
  
)

export default component.exports