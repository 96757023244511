import $ from 'jquery';
import { isFunction, isString } from "@/domain/utils/TypePredicates";

const Config = (() => {
	/**
	 * It's possible for the router to work in one of two ways. Either:
	 *
	 * 1. It will search for a matched route and then immediately exit matching or
	 * 2. It will run all matched callbacks for a given route.
	 *
	 * This is defined by setting the below property to 'single' or 'all'. The
	 * default behaviour is for the router to stop as soon as it finds a match.
	 */
	const config = {
		matchBehaviour: 'single'
	};

	/**
	 * Some configuration may have some special requirements and/or validation for their values. This object
	 * defines those special methods which will be called by Config.set().
	 */
	const setMethods = {
		/**
		 * Sets the match behaviour for the router.
		 */
		matchBehaviour: (behaviour) => {
			if (behaviour !== 'single' && behaviour !== 'all') {
				throw new Error('Invalid value for router match behaviour. Available options are: single, all.');
			}

			config.matchBehaviour = behaviour;
		}
	};

	/**
	 * Returns a single configuration setting.
	 */
	const getConfig = (setting) => config[setting];

	/**
	 * Define or replace a given configuration setting value.
	 */
	const setConfig =  (setting, value) => {
		if (setMethods[setting]) {
			setMethods[setting](value);
		} else {
			if (!config[setting]) {
				throw new Error('Setting [' + setting + '] is not configurable.');
			}
			config[setting] = value;
		}
	};

	return {
		get: getConfig,
		set: setConfig
	};
})();

/**
 * Create a new Route object, with the pattern method and callback handler defined.
 */
const Route = (pattern, method, handler, options) => {
	if (!isString(pattern)) {
		throw new Error('You must provide the pattern argument as a string when registering a new Route object.');
	}
	if (!isString(method)) {
		throw new Error('You must provide the method argument as a string when registering a new Route object.');
	}
	if (!isFunction(handler)) {
		throw new Error('You must provide the handler argument as a function callback when registering a new Route object.');
	}

	options = options || {when: 'after'};

	/**
	 * Converts a given pattern into a regular expression which can be used
	 * for matching.
	 */
	const regexify = (pattern) => {
		const replacedPattern = pattern
			.replace(/:any/gi, '(.*)')
			.replace(/:id/gi, '([0-9]+)')
			.replace(/:alphanum/gi, '([a-z0-9\\-]+)')
			.replace(/:alpha/gi, '([a-z\\-]+)')
			.replace(/:(?=\/)+/gi, '([^/]+)');

		const fullPattern = replacedPattern + '\\/?$';

		return new RegExp(fullPattern);
	};

	/**
	 * Match a given url and method against the registered routes.
	 */
	const matches = (url, method, when = 'after') => {
		if (route.method === method && route.options && route.options.when === when) return route.regex.test(url);

		return false;
	};

	const regex = regexify(pattern);

	const route = {
		pattern,
		regex,
		method,
		handler,
		options,
		matches,
	};

	return route;
};

/**
 * The router class manages the registration of various front-end routes.
 */
const Router = (()=>{
	/**
	 * Stores the registered routes for matching later.
	 */
	let routes = [];

	const get = (pattern, handler, options) => add(pattern, 'get', handler, options);
	const post = (pattern, handler, options) => add(pattern, 'post', handler, options);
	const del = (pattern, handler, options) => add(pattern, 'delete', handler, options);
	const put = (pattern, handler, options) => add(pattern, 'put', handler, options);

	const add = (pattern, method, handler, options) => routes.push(Route(pattern, method, handler, options));

	// todo: it looks like resource function is not used anywhere
	// /**
	//  * You can register a resource that will respond to all method requests to a given
	//  * set of url patterns. When registering a resource (such as "users"), the following
	//  * routes will be registered:
	//  *
	//  *  - GET /users/
	//  *  - POST /users/
	//  *  - DELETE /users/:id
	//  *  - GET /users/:id
	//  *  - PUT /users/:id
	//  *  - POST /users/:id
	//  */
	// const resource = function(name, handler, options) {
	// 	const idPattern = name + '/:id';
	//
	// 	get(name, handler, options);
	// 	post(name, handler, options);
	// 	del(idPattern, handler, options);
	// 	get(idPattern, handler, options);
	// 	put(idPattern, handler, options);
	// 	post(idPattern, handler, options);
	// };

	/**
	 * Match a given url and method against the registered routes.
	 */
	const match = (url, method, when) => {
		const matchedRoutes = [];

		for (let i = 0; i < routes.length; i++) {
			if (routes[i].matches(url, method, when)) {
				matchedRoutes.push(routes[i]);

				if (Config.get('matchBehaviour') === 'single') {
					break;
				}
			}
		}

		return matchedRoutes;
	};

	const clear = () => {
		routes = [];
	};

	const getRoutes = () => routes;

	return {
		clear,
		get,
		post,
		put,
		del,
		delete: del,
		match,
		getRoutes
	};
})();

/**
 * The Eventer is used to hook into jquery-pjax events. It adds a wrapper around pjax events
 * and sets up callbacks based on the routes you register with the system. For example,
 * as requests are made, jquery-pjax fires events. These events are hooked into and every
 * time Eventer will search for one or more matching routes. If one is found, it'll execute the callback
 * for that registered route. If no routes are found/matched, it won't do anything.
 */
const Eventer = (() => {
	/**
	 * Setup a new listener for the required event, and register the callback.
	 */
	const listen = (event, callback) => {
		$(document).on(event, callback);
	};

	/**
	 * Execute the callback provided.
	 */
	const handle = (callback, params) => callback(params)

	/**
	 * This is the default handler for requests, and gets registered
	 * for both the pjax:send and pjax:complete events with jquery-
	 * It attempts to find a matching route for the given URL, and if found
	 * will then execute the callback registered for that route.
	 */
	const requestCallback = (xhr, options, when) =>{
		const method = Utility.determineHttpVerb(xhr, options, when);
		const matchedRoutes = Router.match(Utility.url(options.url), method, when);

		matchedRoutes.forEach((route) => {
			const handlerParams = {
				xhr: xhr,
				options: options,
				route: route
			};

			handle(route.handler, handlerParams);
		});
	};

	// Setup our base event listeners
	listen('pjax:start', (event, xhr, options) => {
		requestCallback(xhr, options, 'before');
	});

	listen('pjax:end', (event, xhr, options) => {
		const alternateUrl = xhr.getResponseHeader('X-PJAX-URL');

		if (alternateUrl) {
			options.url = alternateUrl;
		}

		requestCallback(xhr, options, 'after');
	});

	return { listen };
})();

// todo: it looks like Request and Response classes are not used anywhere
/**
 * The Request class neatly bundles up the information that was provided as part of the request
 * into an object that can be used to query for information.
 */
// const Request = function(xhr, options) {
// 	/**
// 	 * The original XHR object.
// 	 */
// 	this.xhr = xhr;
//
// 	/**
// 	 * The original options object as provided by jquery-
// 	 */
// 	this.options = options;
//
// 	/**
// 	 * The headers that were sent as part of the request.
// 	 */
// 	this.headers = xhr.headers;
// 	this.data = options.data;
// 	this.method = Utility.determineHttpVerb(xhr, options);
// 	this.url = options.url;
// };
//
// /**
//  * The Response class stores the information that was returned via the response, including all headers,
//  * the content, and formats the content if necessary (such as converting a json-encoded string into a
//  * JSON object). It also provides some methods for querying the response. It also contains a reference
//  * to the original request made to the server.
//  */
// const Response = function(xhr, request) {
// 	/**
// 	 * Required for nested anonymous functions.
// 	 */
// 	var that = this;
//
// 	/**
// 	 * The original XHR object.
// 	 */
// 	this.xhr = xhr;
//
// 	/**
// 	 * The headers that were sent as part of the request.
// 	 */
// 	this.headers = xhr.headers;
//
// 	/**
// 	 * The original Request object.
// 	 */
// 	this.request = request;
//
// 	/**
// 	 * The content that was part of the response. This could be a string in the case of
// 	 * an HTML or XML response, or an object if the response was a JSON-encoded string.
// 	 */
// 	this.content = this.isJSON() ? JSON.decode(xhr.response) : xhr.response;
//
// 	/**
// 	 * Determines whether or not the response was of a JSON variety.
// 	 */
// 	this.isJSON = function() {
// 		return xhr.response == 'json';
// 	};
//
// 	/**
// 	 * Determines whether or not the response was an HTML response.
// 	 */
// 	this.isHTML = function() {
// 		return !this.isJSON();
// 	};
// };

/**
 * Provides some utility methods for the Pjax library.
 */
const Utility = (() => {
	/**
	 * There are two ways in which a method can be derived. Because browsers don't fully support all the HTTP
	 * verbs, and the fact that frameworks work around this by providing a _method property as part of form
	 * submissions, we will look first for that _method property in the XHR post data. If it exists, we will
	 * use that as the method. This helps to support requests like PUT/PATCH/DELETE.etc.
	 */
	const determineHttpVerb = (xhr, options, when) => {
		let method = options.type;

		if (options.data && options.data._method) {
			method = options.data._method;
		}

		if (when === 'after') {
			method = 'GET';
		}

		return method.toLowerCase();
	};

	/**
	 * Return the URL minus the query string.
	 */
	const url = (url) => {
		// remove zero width spaces, zero width non-joiner Unicode code point, zero width joiner Unicode code point
		url = decodeURIComponent(url).replace(/[\u200B-\u200D\uFEFF]/g, '');

		return url.split('?')[0];
	};

	return {
		determineHttpVerb: determineHttpVerb,
		url: url
	};
})();

const Init = () => {
	/**
	 * When the page first loads up, PJAX will not be firing, so we want to make a match against
	 * the current document/window URL, and pass this information to the correct handler which
	 * should be able to handle both.
	 */
	const url = Utility.url(window.location.href);
	const matchedRoutes = Router.match(url, 'get', 'after');

	matchedRoutes.forEach((route) => {
		const handlerParams = {
			xhr: null,
			options: null,
			route: route
		};

		route.handler(handlerParams);
	});
};

export { Config, Init, Router, Utility };
