interface Recommendation {
	[key: string]: number;
}

interface Translated {
	[lang: string]: {
		recommendationLabel: string;
	};
}

interface Criterion {
	id: [number, null];
	recommendations: string;
	translated: Translated;
}

interface ParsedRecommendation {
	key: string;
	new: boolean;
	score: number;
	translated: {
		[lang: string]: string;
	};
}

const getTranslatedLabels = (
	translated: Translated,
	supportedLanguages: string[],
	key: string
): { [lang: string]: string } =>
	supportedLanguages.reduce(
		(trans, lang) => ({
			...trans,
			[lang]: translated[lang]?.recommendationLabel ? JSON.parse(translated[lang].recommendationLabel)[key] : '\r\n',
		}),
		{} as { [lang: string]: string }
	);

export function parseRecommendations(criterion: Criterion, supportedLanguages: string[] = []): ParsedRecommendation[] {
	const recommendation: Recommendation = JSON.parse(criterion.recommendations || '{}');
	const translated: Translated = JSON.parse(JSON.stringify(criterion.translated || {}));

	return Object.keys(recommendation).map((key) => ({
		key: key,
		new: !criterion.id || recommendation[key] === 0,
		score: recommendation[key] || 0,
		translated: getTranslatedLabels(translated, supportedLanguages, key),
	}));
}
