/**
 * This module handles selecting a season from the season-selector dropdown.
 */
var $ = require('jquery');
var pjax = require('@/vendor/pjax/pjax').default;

$('.content').on('select2-selecting', '#formSelector', function (e) {
	// Switch Selected Season
	pjax.navigate({ url: '/forms/filterby/' + e.val, container: '#pjaxContainer', method: 'GET' });
});
