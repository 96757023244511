<template>
	<div class="formula-field-wrapper">
		<popover :content="lang.get('fields.form.formula.auto-completed')" trigger="hover" wrapper-tag="span">
			<input
				:id="getId()"
				:value="calculatedValue"
				class="form-control formula-field"
				type="text"
				:disabled="true"
				:aria-required="field.required ? 'true' : 'false'"
				:aria-invalid="hasError"
				:aria-describedby="ariaDescribedby"
			/>
			<span class="formula-icon">
				<i class="af-icons af-icons-auto"></i>
			</span>
		</popover>
	</div>
</template>

<script>
import Field from './Field';
import { HyperFormula } from 'hyperformula';
import { Popover } from 'vue-bootstrap';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
	components: {
		Popover,
	},
	extends: Field,
	inject: ['showFormulaErrors', 'lang'],
	computed: {
		...mapState('global', ['hyperFormulaLicense']),
		...mapGetters('entryForm', ['entryFieldValues']),
		...mapGetters('formulaField', ['getFieldValues', 'formulaForEvaluation']),
		configuration() {
			return this.field.configuration;
		},
		formula() {
			return this.configuration ? JSON.parse(this.configuration).formula || '' : '';
		},
		calculatedValue() {
			const options = { licenseKey: this.hyperFormulaLicense };
			const expression = this.formulaForEvaluation(this.formula);

			if (!expression) {
				return '';
			}

			const sheet = [[expression]];

			const hfInstance = HyperFormula.buildFromArray(sheet, options);

			for (const [slug, value] of Object.entries(this.getFieldValues)) {
				if (slug === this.field.slug) {
					continue;
				}

				if ({ string: true, number: true, boolean: true }[typeof value] || value === null) {
					hfInstance.addNamedExpression(slug, value);
				} else if (value.constructor === Object && 'date' in value) {
					hfInstance.addNamedExpression(slug, new Date(Date.parse(value.date)) || value.date);
				} else if (value.constructor === Object && 'datetime' in value) {
					hfInstance.addNamedExpression(slug, new Date(Date.parse(value.datetime)) || value.datetime);
				} else if (Array.isArray(value)) {
					hfInstance.addNamedExpression(slug, value.join(', '));
				}
			}

			const value = hfInstance.getCellValue({ col: 0, row: 0, sheet: 0 });

			if ({ string: true, number: true, boolean: true }[typeof value] || value === null) {
				return value;
			}

			return this.showFormulaErrors ? value?.value : '';
		},
	},
	watch: {
		calculatedValue() {
			this.onInput(this.calculatedValue, true);
		},
		entryFieldValues() {
			this.setFieldValues(this.entryFieldValues);
		},
	},
	mounted() {
		this.onInput(this.calculatedValue, true);
	},
	created() {
		this.setFieldValues(this.entryFieldValues);
	},
	methods: {
		...mapMutations('formulaField', ['setFieldValues']),
	},
};
</script>
