import $ from 'jquery';
import pjax from '@/vendor/pjax/pjax';
/**
 * This module handles selecting the per-page option from the pagination dropdown.
 */

var updateURLParameter = function (url, param, paramVal, ignore) {
	var newAdditionalURL = '';
	var tempArray = url.split('?');
	var additionalURL = tempArray[1];
	var currentParam = '';
	var temp = '';

	if (additionalURL) {
		tempArray = additionalURL.split('&');

		for (let i = 0; i < tempArray.length; i++) {
			currentParam = tempArray[i].split('=')[0];
			/* eslint-disable-next-line eqeqeq */
			if (currentParam == param || currentParam == ignore) {
				continue;
			}

			newAdditionalURL += temp + tempArray[i];
			temp = '&';
		}
	}

	var rowsTxt = temp + '' + param + '=' + paramVal;

	return newAdditionalURL + rowsTxt;
};

$('.content').on('change', '.per-page', function () {
	var data = updateURLParameter(window.location.href, 'per_page', $(this).val(), 'page');

	pjax.navigate({ url: window.location.href.split('?')[0], data: data, container: '#pjaxContainer', method: 'GET' });
});
