import { render, staticRenderFns } from "./UrlField.vue?vue&type=template&id=7bca5650"
import script from "./UrlField.vue?vue&type=script&lang=js"
export * from "./UrlField.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports