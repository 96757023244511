
import { Tabs } from 'vue-bootstrap';
import sticky from './Shared/mixins/sticky';
import { defineComponent } from 'vue';
import { useController } from '@/domain/services/Composer';
import { tabbedContentController, Props, View } from '@/lib/components/TabbedContent.controller';

export default defineComponent<Props, View>({
	name: 'TabbedContent',
	mixins: [sticky],
	components: {
		Tabs,
	},
	props: {
		tabs: {
			type: Array,
			default: null,
		},
		whenDisabledRedirectTo: {
			type: String,
			default: null,
		},
		stickySelector: {
			type: String,
			default: '.sticky-tabs',
		},
	},
	setup: useController(tabbedContentController, 'TabbedContentController') as () => View,
});
