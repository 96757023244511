import apiKeyRoutes from '@/modules/api-keys/routes';
import assignmentRoutes from '@/modules/assignments/routes';
import auditRoutes from '@/modules/audit/routes';
import authenticatorsRoutes from '@/modules/authenticators/routes';
/**
 * Routes names are the same as in laravels definitions
 * Root components name is calculated by replacing all dots with dashes in route name
 *
 * Imports point to module definition file that uses
 * Composer.registerVuePlugins
 * Composer.registerComponents
 * to register plugins and components used within root component scope
 *
 */
type ModuleLoader = () => Promise<{
	default: () => void;
	name?: string;
}>;

type Routes = Record<string, ModuleLoader>;

const routes = {
	...authenticatorsRoutes,
	...apiKeyRoutes,
	...assignmentRoutes,
	...auditRoutes,
	'allocation-payment.index': () => import('@/modules/allocation-payment/index'),
	'fast-start.index': () => import('@/modules/fast-start/index'),
	'billing.index': () => import('@/modules/billing/index'),
	'test.index': () => import('@/modules/test/index'),
} as Routes;

export { Routes, routes, ModuleLoader };
