import { Collaborator } from '@/domain/models/Collaborator';
import { collaborationUIBus, CollaborationUISignals } from '@/domain/signals/Collaboration';
import { Collectable, Collection } from '@/domain/utils/Types';
import { DataMapper, dataSourceFactory } from '@/domain/services/Rt/Firestore';
import { isArray, isObject } from '@/domain/utils/TypePredicates';
import { Lock, lockableDatSourceMapper } from '@/domain/services/Collaboration/LockableLogic';
import { LockableData, mutableDatSourceMapper } from '@/domain/services/Collaboration/MutableLogic';

enum Collections {
	Links = 'links',
	Attachments = 'attachments',
	Contributors = 'contributors',
	Collaborators = 'collaborators',
	Lockables = 'lockables',
	Referees = 'referees',
}

type Callback<T> = (record: T | null, exists?: boolean) => void;
type Setter<T> = (data: T) => T;
type Value<T> = T;

type DataSource<T> = {
	id: string;
	set: (data: Setter<T | null> | Value<T | null>, forceSet?: boolean) => void;
	get: () => Promise<T | null>;
	remove: () => void;
	subscribe: (callback: Callback<T>, ignoreRepetition?: boolean) => () => void;
	destroy: () => void;
	isReadonly: () => boolean;
};

const dataSourceCache: Record<string, DataSource<any>> = {};

const getCachedDataSource = (dataSourceId: string) => {
	if (dataSourceCache[dataSourceId]) {
		return dataSourceCache[dataSourceId];
	}

	return null;
};

const cacheDataSource = (dataSourceId: string, dataSource: DataSource<any>) => {
	if (dataSourceCache[dataSourceId] !== undefined) {
		dataSourceCache[dataSourceId].destroy();
		delete dataSourceCache[dataSourceId];
	}

	dataSourceCache[dataSourceId] = dataSource;
	return dataSource;
};

collaborationUIBus.on(CollaborationUISignals.RELOAD, () => {
	Object.values(dataSourceCache).forEach((dataSource) => dataSource.destroy());
	Object.keys(dataSourceCache).forEach((key) => delete dataSourceCache[key]);
});

const removeCacheDataSource = (dataSourceId: string) => {
	if (dataSourceCache[dataSourceId] !== undefined) {
		dataSourceCache[dataSourceId].destroy();
		delete dataSourceCache[dataSourceId];
	}
};

const collectionMapper = {
	extractData: <T>(input: Collectable<T> | Collection<T> | null) => {
		if (isArray<T>(input)) {
			return input;
		}

		if (input === null) {
			return [];
		}

		if (!isObject(input) || Object.keys(input).find((key, index) => index !== parseInt(key))) {
			throw new Error(`Invalid collection data: ${JSON.stringify(input)}`);
		}

		return Object.values<T>(input);
	},
};

const document = <T, R = T>(collection: Collections, documentId: string, dataMapper: DataMapper<T, R> = {}) =>
	dataSourceFactory<T, R>(`${collection}-${documentId}`, collection, documentId, dataMapper);

const dataSources = {
	locks: (formSlug: string, submittableSlug: string, lockableId: string) =>
		dataSourceFactory<LockableData, Lock>(
			`locks-${Collections.Lockables}-${formSlug}-${submittableSlug}-${lockableId}`,
			Collections.Lockables,
			`${formSlug}-${submittableSlug}-${lockableId}`,
			lockableDatSourceMapper
		),

	collaborators: (formSlug: string, submittableSlug: string) =>
		document<Collectable<Collaborator>, Collection<Collaborator>>(
			Collections.Collaborators,
			`${formSlug}-${submittableSlug}`,
			collectionMapper
		),

	/**
	 * Generic collection used for links, contributors, and collaborators
	 */
	collection: <T>(formSlug: string, submittableSlug: string, collectionId: Collections) =>
		document<Collectable<T>, Collection<T>>(collectionId, `${formSlug}-${submittableSlug}`, collectionMapper),

	/**
	 * Generic document used for attachments
	 */
	document: <T>(formSlug: string, submittableSlug: string, collectionId: Collections) =>
		document<T>(collectionId, `${formSlug}-${submittableSlug}`),

	lockables: (formSlug: string, submittableSlug: string, lockableId: string) =>
		dataSourceFactory<LockableData>(
			`lockables-${Collections.Lockables}-${formSlug}-${submittableSlug}-${lockableId}`,
			Collections.Lockables,
			`${formSlug}-${submittableSlug}-${lockableId}`,
			mutableDatSourceMapper,
			false
		),
};

export {
	dataSources as default,
	DataSource,
	Callback,
	Setter,
	Value,
	Collections,
	getCachedDataSource,
	removeCacheDataSource,
	cacheDataSource,
	collectionMapper,
};
