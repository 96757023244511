// eslint-disable-next-line import/exports-last
export const defaultState = () => ({
	routeSet: null,
	errorBag: [],
	ajaxInProgress: false,
	message: null,
	autosaveEnabled: true,
});

const state = defaultState();

export default state;
