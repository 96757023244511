/**
 * ToDo: this is a patch for old pjax router in non refactored modules
 *
 * to use it switch in modules/../routes.js
 * from this: import { Router } from '@/vendor/pjax/pjax-router.js';
 * to this: import { Router } from '@/legacy/Router';
 *
 * for refactored modules this is not needed
 */
import { Router as PjaxRouter } from '@/vendor/pjax/pjax-router.js';
import Vue from 'vue';

// al these modules are bundled with all legacy modules / components
const withPlugins = async (handler: () => void) => {
	Promise.all([
		import('@/plugins/pinia'),
		import('@/plugins/portal-vue'),
		import('@/plugins/vue-outside-events'),
		import('@/plugins/vuex'),
	]).then((plugins) => {
		plugins.forEach((plugin) => plugin.default(Vue));
		handler();
	});
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const Router = {
	get: (pattern: string, handler: () => void, options?: { [name: string]: unknown }) =>
		PjaxRouter.get(pattern, () => withPlugins(handler), options),
};

export { Router };
