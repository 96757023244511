<script>
import ResourceList from '@/lib/components/ResourceList.vue';
import ListAction from '@/lib/components/ListActions/ListAction.vue';
import ScoreMatrix from '@/modules/judging/components/ScoreMatrix.vue';

export default {
	components: {
		ListAction,
		ScoreMatrix,
	},
	extends: ResourceList,
	computed: {
		scoreMatrixRevealed() {
			return this.reveal === 'score-matrix';
		},
	},
};
</script>
