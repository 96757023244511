/**
 * This file is simply for bootstrapping all the other modules, and their relevant
 * javascript files. Think of it as a module index for the front-end.
 */

require('./authenticators/routes.js');
require('./awards/routes.js');
require('./broadcasts/routes.js');
require('./category/routes.js');
require('./chapters/routes.js');
require('./content-block/routes.js');
require('./currencies/routes.js');
require('./interface-text/routes.js');
require('./dashboard/routes.js');
require('./discounts/routes.js');
require('./documents/routes.js');
require('./document-templates/routes.js');
require('./ecommerce/routes.js');
require('./entries/routes.js');
require('./entry-form/routes.js');
require('./exports/routes.js');
require('./features/routes.js');
require('./fields/routes.js');
require('./funding/routes.js');
require('./gallery/routes.js');
require('./gallery-dashboard/routes.js');
require('./grant-reports/routes.js');
require('./guides-and-tours/index.js');
require('./grant-reports/routes.js');
require('./home/routes.js');
require('./integrations/routes.js');
require('./judging/routes.js');
require('./judging-dashboard/routes.js');
require('./judge-dashboard/routes.js');
require('./leaderboard/routes.js');
require('./orders/routes.js');
require('./panel/routes.js');
require('./payment-methods/routes.js');
require('./payment-schedule-templates/routes.js');
require('./payment/routes.js');
require('./payment-subscriptions/routes.js');
require('./price/routes.js');
require('./provisioning/routes.js');
require('./qualifying/routes.js');
require('./reports/routes.js');
require('./review-flow/routes.js');
require('./role/routes.js');
require('./rounds/routes.js');
require('./scoring-criteria/routes.js');
require('./score-set/routes.js');
require('./setting/routes.js');
require('./tabs/routes.js');
require('./tags/routes.js');
require('./taxes/routes.js');
require('./theme/routes.js');
require('./tiers/routes.js');
require('./top-pick/routes.js');
require('./users/routes.js');
require('./notifications/routes.js');
require('./voting/routes.js');
require('./webhooks/routes.js');
require('./contract/routes.js');
require('./forms/routes.js');
require('./seasons/routes.js');
require('./domain-setup/routes.js');
require('./grants/routes.js');
require('./clear/routes.js');
