import _ from 'underscore';
import CryptoJS from 'crypto-js';

type Encrypter<T = string> = {
	encrypt: (data: T) => string;
	decrypt: (cryptogram: string | null) => T;
};

/**
 * Laravel compatibile encrypter
 * encrypt in laravel:

	use Illuminate\Encryption\Encrypter
	$token = '0123456789ABCDEF';
 	$encrypter = new Encrypter($token);
 	$cryptogram = $encrypter->encryptString(json_encode([ 'foo'=>'bar']));

 * decrypt in js:

 import { jsonEncrypter } from '@/domain/services/Encrypter';
 jsonEncrypter('0123456789ABCDEF').decrypt(cryptogram);

 */

const options = {
	iv: CryptoJS.lib.WordArray.random(16),
	mode: CryptoJS.mode.CBC,
	padding: CryptoJS.pad.Pkcs7,
};

const encrypt =
	(key: string) =>
	(plaintext: string): string => {
		const value = CryptoJS.AES.encrypt(plaintext, CryptoJS.enc.Utf8.parse(key), options).toString();
		const iv = CryptoJS.enc.Base64.stringify(options.iv);

		return CryptoJS.enc.Base64.stringify(
			CryptoJS.enc.Utf8.parse(
				JSON.stringify({
					iv,
					value,
					mac: CryptoJS.HmacSHA256(iv + value, key).toString(),
				})
			)
		);
	};

const decrypt =
	(key: string) =>
	(cryptogram: string | null): string | null => {
		if (!cryptogram) {
			return cryptogram;
		}

		const encryptedData = JSON.parse(CryptoJS.enc.Base64.parse(cryptogram).toString(CryptoJS.enc.Utf8));
		const iv = CryptoJS.enc.Base64.parse(encryptedData.iv);
		const decrypted = CryptoJS.AES.decrypt(encryptedData.value, CryptoJS.enc.Utf8.parse(key), {
			iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		});

		return CryptoJS.enc.Utf8.stringify(decrypted);
	};

const encrypter = (key: string) => {
	if (CryptoJS.enc.Utf8.parse(key).sigBytes < 12) {
		throw new Error('Passphrase must be at least 12 characters long');
	}

	return {
		encrypt: encrypt(key),
		decrypt: decrypt(key),
	};
};

const jsonEncrypter = <T>(key: string) => ({
	encrypt: (data: T) => encrypter(key).encrypt(JSON.stringify(data)),
	decrypt: (cryptogram: string | null): T | null => {
		const decrypted = encrypter(key).decrypt(cryptogram);
		return decrypted ? JSON.parse(decrypted) : null;
	},
});

/**
 * Selective encrypter
 * omits some properties from encryption
 * stores cryptogram i `encrypted` property
 */
const selectiveEncrypter = <
	T,
	K extends keyof T = keyof T,
	ED extends Partial<T> & { encrypted: string } = Partial<T> & { encrypted: string }
>(
	rawEncrypter: Encrypter<unknown>,
	unencryptedProps: K[] = []
) => ({
	encrypt: (data: T | null) =>
		data
			? {
					..._.pick(data, unencryptedProps),
					...{ encrypted: rawEncrypter.encrypt(_.omit(data, unencryptedProps)) },
			  }
			: null,
	decrypt: (cryptogram: ED | null): T | null =>
		cryptogram !== null
			? {
					..._.pick(cryptogram, unencryptedProps),
					...(rawEncrypter.decrypt(cryptogram?.encrypted) as T),
			  }
			: null,
});

/**
 * Transparent encrypter
 * used for debug purposes
 */
// eslint-disable-next-line no-unused-vars
const transparentEncrypter = <T>(key: string) => ({
	encrypt: (data: T) => data as unknown as string,
	decrypt: (cryptogram: string | null): T => cryptogram as unknown as T,
});

export { encrypter, jsonEncrypter, selectiveEncrypter, transparentEncrypter, type Encrypter };
