<template>
	<fieldset>
		<div v-for="recommendation in recommendations" :key="recommendation.key" class="radio styled">
			<input
				:id="recommendation.key"
				name="recommendation"
				type="radio"
				:value="recommendation.score"
				:class="{ disabled: lockScores }"
			/>
			<label :for="recommendation.key">
				{{ recommendation.translated[lang.locale] ?? recommendation.translated[lang.fallback] }}
			</label>
		</div>
	</fieldset>
</template>

<script>
export default {
	props: {
		lockScores: {
			type: Boolean,
			default: false,
		},
		recommendations: {
			type: Array,
			default: () => [],
		},
	},
	inject: ['lang'],
};
</script>
